import { Button } from "@/components/ui/button";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import Spinner from "@/components/ui/spinner";
import { DateUtils } from "@/lib/utils/date.utils";
import CPReservationV3 from "@clicknpark/sdk/dist/types/entities/v3/reservations/CPReservationV3";
import dayjs from "dayjs";
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Props {
  reservations: CPReservationV3[];
  loading: boolean;
}

export default function ReservationsList({ reservations, loading }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation(["reservations"]);

  return loading ? (
    <div className="p-24">
      <Spinner />
    </div>
  ) : reservations.length > 0 ? (
    <ul className="space-y-4 mt-5">
      {reservations?.map((reservation) => (
        <li key={reservation.id} onClick={() => navigate(`/account/reservations/${reservation.id}`)} className="cursor-pointer">
          <Card className="flex justify-between">
            <CardHeader className="pb-0">
              <CardTitle>{reservation.park.address.line1}</CardTitle>
              <CardDescription>
                {DateUtils.formatDate(dayjs(reservation.startDate))}
                <ArrowRight className="inline-block mx-2 size-4" />
                {DateUtils.formatDate(dayjs(reservation.endDate))}
              </CardDescription>
            </CardHeader>

            <Button size="sm" onClick={() => navigate(`/account/reservations/${reservation.id}`)} className="md:block hidden">
              {t("reservations:view")}
            </Button>
          </Card>
        </li>
      ))}
    </ul>
  ) : (
    <p className="py-6">{t("reservations:noReservations")}</p>
  );
}
