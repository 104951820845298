import Spinner from "@/components/ui/spinner";
import cn from "@/lib/utils/cn.utils";

interface Props {
  backgroundStyle?: "transparent" | "opaque";
  containerPaddingClassName?: string;
  loaderPosition?: "top" | "center"; // When the container is very tall, the loader can be placed at the top
  title?: string;
  description?: string;
}

export default function Loader({ backgroundStyle, loaderPosition, containerPaddingClassName, title, description }: Props) {
  let bgClassName = "";

  switch (backgroundStyle) {
    case "transparent":
      bgClassName = "bg-transparent";
      break;
    case "opaque":
      bgClassName = "bg-white";
      break;
    default:
      bgClassName = "bg-white/90";
  }

  return (
    <span
      className={cn(
        `block absolute bottom-0 left-0 right-0 top-0 z-50 ${bgClassName} flex flex-col items-center justify-center transition duration-200 ease-in-out`,
        loaderPosition === "top" ? "items-start" : "items-center",
        containerPaddingClassName
      )}
    >
      <Spinner />

      {title ? (
        <div className="mt-4 flex-col justify-center items-center text-center">
          <h3 className="text-sm font-semibold">{title}</h3>
          {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
        </div>
      ) : null}
    </span>
  );
}
