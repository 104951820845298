interface Props {}

export default function PaymentIcons({}: Props) {
  const imgClassName = "w-8 rounded-[3px]";

  return (
    <div className="flex items-center justify-center space-x-1 max-w-full pt-3">
      <img
        src="/images/payment-icons/visa.svg"
        alt="Visa"
        className={imgClassName}
      />
      <img
        src="/images/payment-icons/mastercard.svg"
        alt="Mastercard"
        className={imgClassName}
      />
      <img
        src="/images/payment-icons/amex.svg"
        alt="Amex"
        className={imgClassName}
      />
      <img
        src="/images/payment-icons/apple-pay.svg"
        alt="Apple Pay"
        className={imgClassName}
      />
      <img
        src="/images/payment-icons/stripe.svg"
        alt="Stripe"
        className={imgClassName}
      />
    </div>
  );
}
