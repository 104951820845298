interface Props {
  width?: number;
  height?: number;
  strokeColor?: string;
  strokeWidth?: number;
  notchColor?: string;
}

export default function SvgError({ strokeColor = "#008FFF", notchColor = "#FFFFFF", strokeWidth = 15, width = 500, height = 500 }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 755 606" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M576.192 486.746V525.996C576.192 570.49 612.262 606.56 656.757 606.56V606.56C701.252 606.56 737.322 570.49 737.322 525.996V396.886"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="M17.8619 396.886V525.996C17.8619 570.49 53.932 606.56 98.4266 606.56V606.56C142.921 606.56 178.991 570.49 178.991 525.996V486.746"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.8613 239.64C29.837 239.64 10.3613 259.115 10.3613 283.14V450.767C10.3613 474.791 29.837 494.267 53.8614 494.267H721.319C734.298 494.267 744.819 483.746 744.819 470.767V283.14C744.819 259.115 725.344 239.64 701.319 239.64H53.8613ZM25.3613 283.14C25.3613 267.4 38.1212 254.64 53.8613 254.64H115.804V239.669L159.429 239.669L184.794 239.669L184.794 254.64H701.319C717.059 254.64 729.819 267.4 729.819 283.14V470.767C729.819 475.461 726.014 479.267 721.319 479.267H53.8614C38.1213 479.267 25.3613 466.507 25.3613 450.767V283.14Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.681 0C149.135 0 127.739 16.7029 121.781 40.5146L70.5418 245.307L68.2098 254.627H77.8175H115.804V239.668H159.429H184.793L184.793 254.627H677.366H686.974L684.642 245.307L633.403 40.5145C627.445 16.7029 606.048 0 581.502 0H173.681ZM136.332 44.1553C140.62 27.0199 156.017 15 173.681 15H581.502C599.166 15 614.564 27.0198 618.851 44.1553L667.758 239.627H87.4252L136.332 44.1553Z"
        fill={strokeColor}
      />
      <path d="M173.149 247.152H127.762H156.462M173.149 247.152H156.462H127.762" stroke={notchColor} strokeWidth={strokeWidth} />
      <path d="M0 209.687H59.9551" stroke={strokeColor} strokeWidth={strokeWidth} />
      <path d="M693.998 209.687H753.953" stroke={strokeColor} strokeWidth={strokeWidth} />
      <ellipse cx="137.772" cy="366.935" rx="59.9551" ry="59.907" stroke={strokeColor} strokeWidth={strokeWidth} />
      <ellipse cx="617.413" cy="366.935" rx="59.9551" ry="59.907" stroke={strokeColor} strokeWidth={strokeWidth} />
      <path d="M582.155 185.107C547.568 150.548 491.492 150.548 456.906 185.107" stroke={strokeColor} strokeWidth={strokeWidth} />
      <path d="M464.446 368.55C416.583 347.834 338.077 346.321 289.1 370.669" stroke={strokeColor} strokeWidth={strokeWidth} />
    </svg>
  );
}
