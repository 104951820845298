import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { HandledError } from "@/lib/services/helpers/clicknpark-errors.helpers";

interface Props {
  error?: HandledError;
}

export default function ErrorAlert({ error }: Props) {
  return (
    <>
      {error ? (
        <Alert variant="error">
          {error?.formatted.title ? <AlertTitle>{error?.formatted.title}</AlertTitle> : null}
          {error?.formatted.description ? <AlertDescription>{error?.formatted.description}</AlertDescription> : null}
        </Alert>
      ) : null}
    </>
  );
}
