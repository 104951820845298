// Routes config
// ==========================================

/* ------------------------ Routes paths

 Paths are used in multiple ways:

 - In the routes.ts files to map paths to the right component
 - In the menu.config.ts file for mapping menu items to routes
 - with the getRoute function to format the URL for navigation

 Basically, it is used as the basis of truth for every paths in the app.

 */

export const ROUTE_PATHS = {
  SEARCH_DEFAULT: "/",
  SEARCH_SELECT_EVENT: "/venue/:venueId",
  SEARCH_SELECT_PARK_FOR_EVENT: "/venue/:venueId/:eventId",
  SEARCH_SELECT_TIME: "/park/:parkId",
  SEARCH_SELECT_TIME_PRESET: "/park/:parkId?start=:start&end=:end",
  SEARCH_SELECT_TIME_FOR_EVENT: "/park/:parkId?vid=:venueId&eid=:eventId&start=:start&end=:end&mode=custom",
  PROMO_CODE: "/promo-code",
  DOWNLOAD_DEFAULT: "/download",
  DOWNLOAD_PARK: "/download?pid=:parkId",
  ACCOUNT_PROFILE: "/account/profile",
  ACCOUNT_RESERVATIONS: "/account/reservations",
};

type RouteParams = {
  SEARCH_SELECT_EVENT: { venueId: string; eventId: string };
  SEARCH_SELECT_PARK_FOR_EVENT: { venueId: string; eventId: string };
  SEARCH_SELECT_TIME: { parkId: string };
  SEARCH_SELECT_TIME_PRESET: { parkId: string; start: string; end: string };
  SEARCH_SELECT_TIME_FOR_EVENT: { parkId: string; eventId: string; venueId: string; start: string; end: string };
  DOWNLOAD_PARK: { parkId: string };
};

export function getRoute(
  route: keyof typeof ROUTE_PATHS,
  params?: RouteParams[keyof RouteParams]
) {
  let path = ROUTE_PATHS[route];

  if (params) {
    for (const key in params) {
      // @ts-ignore
      path = path.replace(`:${key}`, params[key]);
    }
  }
  return path;
}
