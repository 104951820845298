import { cookieOptions } from "@/lib/configs/cookie.config";
import { removeAuthTokenHeader } from "@/lib/services/config/api";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import { useCookies } from "react-cookie";

export default function useOnLogout() {
  const [_, __, removeCookie] = useCookies([keys.AUTH_TOKEN, keys.BIFROST_EMAIL]);

  return () => {
    StorageUtils.clear();
    removeAuthTokenHeader();
    removeCookie(keys.AUTH_TOKEN, cookieOptions);
    removeCookie(keys.BIFROST_EMAIL, cookieOptions);
    window.location.reload();
  };
}
