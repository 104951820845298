import { Photo } from "@clicknpark/sdk";
import { Fullscreen } from "lucide-react";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";

interface Props {
  sources: Photo[];
}

export default function ParkPhotos({ sources }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  return (
    <div className="overflow-x-auto">
      <div className="flex space-x-4 flex-nowrap" style={{ width: sources.length * 220 }}>
        {sources.map((source, index) => {
          return (
            <div
              className="overflow-hidden w-[220px] h-[120px] rounded-lg cursor-pointer relative"
              key={source.id + index}
              onClick={() => {
                setActiveImageIndex(index);
                setModalOpen(true);
              }}
            >
              <div
                className="w-full h-full z-20 bg-cover bg-center transition-all hover:transform hover:scale-105 duration-500"
                style={{ backgroundImage: `url(${source?.fullSize?.url || ""})` }}
              />

              <div className="absolute bottom-3 right-3 flex items-center space-x-2 text-xs text-white">
                <Fullscreen className="w-5 h-5" />
              </div>
            </div>
          );
        })}
      </div>

      <Lightbox
        open={modalOpen}
        index={activeImageIndex}
        slides={sources.map((source) => ({ src: source?.fullSize?.url || "" }))}
        close={() => setModalOpen(false)}
      />
    </div>
  );
}
