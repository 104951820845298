import { axiosClient, clicknpark } from "@/lib/services/config/api";
import { CPEvent } from "@clicknpark/sdk";
import { CPVenueEvent } from "@clicknpark/sdk/dist/types/entities/latest/events/CPVenueEvent";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

const X_API_KEY = import.meta.env.VITE_X_API_KEY as string;

// Get Events (GET)
// ========================================

export const GET_EVENTS_KEY = "get-events";

type GetEventsData = {
  venueId?: string;
};

const getEvents = async ({ venueId }: GetEventsData): Promise<CPEvent[]> => {
  if (!venueId) throw new Error("venueId is required");
  const startOfDay = dayjs().startOf("day");
  const response = await axiosClient.get<CPEvent[]>(
    `/v2/venues/${venueId}/events?startsAfter=${startOfDay.toISOString()}&endsAfter=${dayjs().toISOString()}`,
    {
      headers: {
        "x-api-key": X_API_KEY,
      },
    }
  );

  return response.data;
};

export const useGetEventsQuery = (data: GetEventsData) => {
  return useQuery<CPEvent[], Error>({
    queryKey: [GET_EVENTS_KEY, data.venueId],
    queryFn: () => getEvents(data),
    staleTime: 1000 * 60 * 1, // 1 minute
  });
};

// Get Event by ID (GET)
// ========================================

export const GET_EVENT_KEY = "get-event";

type GetEventData = {
  venueId?: string;
  eventId?: string;
  locale?: string;
};

const getEvent = async ({ venueId, eventId, locale }: GetEventData): Promise<CPVenueEvent> => {
  if (!venueId) throw new Error("venueId is required");
  if (!eventId) throw new Error("eventId is required");
  if (!locale) throw new Error("locale is required");
  return clicknpark.events.get(eventId, venueId, locale);
};

export const useGetEventQuery = (data: GetEventData) => {
  return useQuery<CPVenueEvent, Error, CPVenueEvent>({
    queryKey: [GET_EVENT_KEY, data.venueId, data.eventId],
    queryFn: () => getEvent(data),
    enabled: !!data.venueId && !!data.eventId && !!data.locale,
  });
};
