import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ArrowRight, ChevronRight } from "lucide-react";

interface Props {
  month: string;
  day: string;
  eventTitle: string;
  startTime: string;
  endTime: string;
  onClick: () => void;
}

export default function Event({ month, day, eventTitle, startTime, endTime, onClick }: Props) {
  return (
    <li
      className="text-simple-900 mt-3 flex items-center p-5 border first:border-t border-b border-rapide-300 rounded-[12px] hover:border-simple-900 hover:bg-rapide-100 bg-rapide-50 transition-colors cursor-pointer"
      onClick={onClick}
    >
      <div className="flex-col items-center justify-center pr-6 py-2 border-r border-silver-700 md:w-auto w-[72px] md:flex hidden">
        <strong className="text-rapide-600 text-md">{month}</strong>
        <strong className="md:text-3xl text-2xl leading-tight">{day}</strong>
      </div>
      <div className="flex-1 md:pl-6">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="font-bold text-md leading-tight pb-1 text-left pr-2">{eventTitle}</div>
            </TooltipTrigger>
            <TooltipContent>
              <p>{eventTitle}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <div className="items-center text-slate-600 flex">
          <span className="text-[11px] font-medium">{startTime}</span>
          <ArrowRight className="w-3.5 h-3.5 mx-1" />
          <span className="text-[11px] font-medium">{endTime}</span>
        </div>
      </div>

      <ChevronRight className="w-6 h-6 text-rapide-600 ml-5 md:hidden block" />
    </li>
  );
}
