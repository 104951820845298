import cn from "@/lib/utils/cn.utils";
import { cva, type VariantProps } from "class-variance-authority";
import { CircleAlertIcon, CircleCheckIcon, CircleXIcon, InfoIcon, TriangleAlertIcon } from "lucide-react";
import * as React from "react";

const alertVariants = cva("flex items-stretch gap-2 rounded-lg", {
  variants: {
    variant: {
      noBorder: "bg-white",
      default: "bg-white border-2 border-slate-200 ",
      info: "bg-white border-2 border-rapide-600 ",
      warning: "bg-white border-2 border-yellow-500 ",
      success: "bg-white border-2 border-green-600 ",
      error: "bg-white border-2 border-red-500 ",
      softError: "border-attention-600 border-2 text-black ",
    },
    size: {
      xs: "text-xs",
      sm: "text-sm",
      md: "text-base",
      lg: "text-md",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "md",
  },
});

const getIcon = ({ variant, size }: VariantProps<typeof alertVariants>) => {
  let iconSize, containerSize;
  switch (size) {
    case "xs":
      iconSize = "h-5 w-5";
      containerSize = "p-2 py-3";
      break;
    case "sm":
      iconSize = "h-5 w-5";
      containerSize = "p-2 py-3";
      break;
    case "md":
      iconSize = "h-6 w-6";
      containerSize = "p-3 py-5";
      break;
    case "lg":
      iconSize = "h-8 w-8";
      containerSize = "p-6 py-8";
      break;
    default:
      iconSize = "h-6 w-6";
      containerSize = "p-3 py-5";
  }
  switch (variant) {
    case "info":
      return (
        <div className={cn("bg-rapide-600 text-white", containerSize)}>
          <InfoIcon className={iconSize} />
        </div>
      );
    case "warning":
      return (
        <div className={cn("bg-yellow-500 text-yellow-800", containerSize)}>
          <TriangleAlertIcon className={iconSize} />
        </div>
      );
    case "success":
      return (
        <div className={cn("bg-green-600 text-white", containerSize)}>
          <CircleCheckIcon className={iconSize} />
        </div>
      );
    case "error":
      return (
        <div className={cn("bg-red-500 text-white", containerSize)}>
          <CircleXIcon className={iconSize} />
        </div>
      );
    case "softError":
      return (
        <div className={cn("bg-attention-600 text-white", containerSize)}>
          <CircleAlertIcon className={iconSize} />
        </div>
      );
    default:
      return null;
  }
};

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants> & { customIcon?: React.ReactNode }
>(({ className, variant, size = "sm", customIcon, ...props }, ref) => {
  let containerSize;
  switch (size) {
    case "xs":
      containerSize = "p-2 py-3";
      break;
    case "lg":
      containerSize = "p-6 py-8";
      break;
    case "sm":
      containerSize = "p-2 py-3";
      break;
    default:
      containerSize = "p-3 py-5";
  }
  return (
    <div ref={ref} role="alert" className={cn(alertVariants({ variant, size }), className)} {...props}>
      {customIcon || getIcon({ variant, size })}
      <div className={containerSize}>
        {React.Children.map(props.children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { size } as any);
          }
          return child;
        })}
      </div>
    </div>
  );
});

Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement> & { size?: string }>(
  ({ className, size, ...props }, ref) => {
    let titleSize;
    switch (size) {
      case "xs":
        titleSize = "text-[13px]";
        break;
      case "lg":
        titleSize = "text-lg";
        break;
      case "sm":
        titleSize = "text-sm";
        break;
      default:
        titleSize = "text-md";
    }
    return <h5 ref={ref} className={cn("font-bold", titleSize, className)} {...props} />;
  }
);

AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement> & { size?: string }>(
  ({ className, size, ...props }, ref) => {
    let descriptionSize;
    switch (size) {
      case "xs":
        descriptionSize = "text-[12px]";
        break;
      case "lg":
        descriptionSize = "text-base";
        break;
      case "sm":
        descriptionSize = "text-sm";
        break;
      default:
        descriptionSize = "text-md";
    }
    return <div ref={ref} className={cn(descriptionSize, className)} {...props} />;
  }
);

AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription, AlertTitle };
