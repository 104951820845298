import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "@/lib/store/configs/defaut-search-params";
import { createContext } from "react";

interface SelectTimeContextType {
  parkId?: string;
  venueId?: string;
  eventId?: string;
  start: string;
  end: string;
}

const SelectParkTimeContext = createContext<SelectTimeContextType>({
  start: DEFAULT_START_DATE,
  end: DEFAULT_END_DATE,
});

export default SelectParkTimeContext;
