import { CPClient } from "@clicknpark/sdk";
import axios from "axios";

const CLICKNPARK_API_URL = import.meta.env.VITE_CLICKNPARK_API_URL as string;

// Clicknpark API SDK client
export const clicknpark = new CPClient(CLICKNPARK_API_URL);

export const setAuthTokenHeader = (token: string) => {
  clicknpark.setAuthToken(token);
  axiosClient.defaults.headers.common["x-auth-token"] = token;
};

export const removeAuthTokenHeader = () => {
  clicknpark.removeAuthToken();
  delete axiosClient.defaults.headers.common["x-auth-token"];
};

// Axios client for other requests
export const axiosClient = axios.create({ baseURL: CLICKNPARK_API_URL });

