import i18next from "@/lib/i18n";
import { SUPPORTED_LANGUAGES } from "@/lib/i18n/index";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import { useState } from "react";

export type Language = "en" | "fr";

export function useLanguageUtils() {
  const [language, setLanguage] = useState<Language>(i18next.language as Language);
  const [alternateLanguage, setAlternateLanguage] = useState(i18next.language === "en" ? "fr" : "en");

  const isSupportedLanguage = (language: Language) => {
    return SUPPORTED_LANGUAGES.includes(language);
  };

  const switchLanguage = (newLanguage: Language) => {
    i18next.changeLanguage(newLanguage);
    setLanguage(newLanguage);
    setAlternateLanguage(newLanguage === "en" ? "fr" : "en");
    StorageUtils.setItem(keys.LANGUAGE, newLanguage);
  };

  const getLanguageLabel = (outputLanguage: Language) => {
    switch (language) {
      case "en":
        return outputLanguage === language ? "English" : "Anglais";
      case "fr":
        return outputLanguage === language ? "Français" : "French";
      default:
        return undefined;
    }
  };

  return {
    language,
    alternateLanguage,
    isSupportedLanguage,
    switchLanguage,
    getLanguageLabel,
  };
}
