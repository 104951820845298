import { Skeleton } from "@/components/ui/skeleton";
import cn from "@/lib/utils/cn.utils";

interface Props {
  loading?: boolean;
  title?: string;
  subtitle?: string;
}

export default function HeaderVenue({ loading, title, subtitle }: Props) {
  return (
    <header className="p-5 py-10 bg-simple-900 text-white">
      <div>
        {loading ? (
          <div className="space-y-2">
            <Skeleton className="w-3/4 h-3 bg-rapide-50/5" />
            <Skeleton className="w-full h-3 bg-rapide-50/5" />
          </div>
        ) : (
          <>
            {subtitle ? <div className="mb-1 text-rapide-600 text-sm font-semibold">{subtitle}</div> : null}
            {title ? <div className={cn("text-3xl block font-heading font-extrabold")}>{title}</div> : null}
          </>
        )}
      </div>
    </header>
  );
}
