import Header from "@/components/containers/search/sidebar/header/header-venue";
import Event from "@/components/containers/search/sidebar/lists/tile/event-tile";
import Loader from "@/components/layout/loader";
import { ScrollArea } from "@/components/ui/scroll-area";
import { getRoute } from "@/lib/configs/routes.config";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { useMediaQuery } from "@/lib/hooks/use-media-query";
import { useGetEventsQuery } from "@/lib/services/events.services";
import { useGetParkGroupQuery } from "@/lib/services/park-groups.services";
import { useMapStore } from "@/lib/store/map.store";
import { DateUtils } from "@/lib/utils/date.utils";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function SelectEvent() {
  const navigate = useNavigate();
  const { t } = useTranslation(["search"]);
  const { language } = useLanguageUtils();
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const params = useParams<{ venueId: string }>();
  const venueId = params.venueId;

  const getEventsQuery = useGetEventsQuery({ venueId });
  const events = getEventsQuery.data;

  const getParkGroupQuery = useGetParkGroupQuery({ parkGroupId: venueId, locale: language });
  const parkGroup = getParkGroupQuery.data;

  const { setMarkers, setCenter, recenter } = useMapStore();

  useEffect(() => {
    if (!parkGroup) return;

    setCenter({
      lat: parkGroup.location.latitude,
      lng: parkGroup.location.longitude,
    });

    setMarkers([
      {
        type: "destination",
        label: parkGroup.name,
        id: parkGroup.id,
        coordinates: {
          lat: parkGroup.location.latitude,
          lng: parkGroup.location.longitude,
        },
      },
      // @ts-ignore
      ...(parkGroup?.parks.map((p, index) => ({
        type: "parking",
        label: `P${++index}`,
        id: p.id,
        coordinates: {
          lat: p.location.latitude,
          lng: p.location.longitude,
        },
      })) || []),
    ]);

    recenter();
  }, [parkGroup]);

  useEffect(() => {
    getParkGroupQuery.refetch();
  }, [language]);

  function selectEvent(eventId: string) {
    venueId &&
      navigate(
        getRoute("SEARCH_SELECT_PARK_FOR_EVENT", {
          venueId,
          eventId,
        })
      );
  }

  const Content = () => (
    <div>
      <Header loading={getParkGroupQuery.isLoading} title={parkGroup?.name} subtitle={t("search:parkingNear")} />

      {getEventsQuery.isLoading ? (
        <section className="relative p-20">
          <Loader />
        </section>
      ) : (
        <section className="p-5">
          <strong className="text-base font-bold text-simple-900 mb-4">{t("search:selectYourEvent")}</strong>

          <ul>
            {events?.map((event, index) => {
              const date = dayjs(event.eventStart.iso);
              const month = date.format("MMM");
              const dayNumber = parseInt(date.format("D"));
              const dayString = dayNumber < 10 ? `0${dayNumber}` : `${dayNumber}`;

              return (
                <Event
                  key={event.objectId + index}
                  {...{
                    month,
                    day: dayString,
                    eventTitle: event?.localizedEventName?.[language as "fr" | "en"] || "",
                    startTime: DateUtils.formatDate(dayjs(event.bufferedEventStart), { locale: language }),
                    endTime: DateUtils.formatDateRelative(dayjs(event.bufferedEventEnd), {
                      locale: language,
                      relativeTo: dayjs(event.bufferedEventStart),
                    }),
                    onClick: () => selectEvent(event.objectId),
                  }}
                />
              );
            })}
          </ul>
        </section>
      )}
    </div>
  );

  return isDesktop ? (
    <ScrollArea className="h-[calc(100vh-68px)]">
      <Content />
    </ScrollArea>
  ) : (
    <Content />
  );
}
