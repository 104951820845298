import cn from "@/lib/utils/cn.utils";
import { Link } from "react-router-dom";

export interface NavLinkProps {
  label: string;
  icon?: React.ReactNode;
  locale?: string;
  href: string;
  onClick?: () => void;
}

export function isNavLink(props: any): props is NavLinkProps {
  return typeof props.label === "string" && typeof props.href === "string";
}

export default function NavLink({ label, href, locale, icon, onClick }: NavLinkProps) {
  return (
    <Link
      {...{
        to: href,
        locale,
        onClick,
      }}
      className={cn(
        "flex h-full items-center gap-2 hover:text-rapide-600 xl:px-4",
        // Mobile menu
        "py-3"
      )}
    >
      {icon}
      <span>{label}</span>
    </Link>
  );
}
