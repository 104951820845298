export const contact = {
  helpPhone: {
    formatted: "+1 855 979-7275",
    unformatted: "18559797275",
  },
  helpEmail: {
    fr: "aide@clicknpark.com",
    en: "help@clicknpark.com",
  },
};
