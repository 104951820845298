import i18next from "@/lib/i18n";
import { axiosClient, clicknpark } from "@/lib/services/config/api";
import { retry } from "@/lib/services/helpers/clicknpark-errors.helpers";

import { NumberFormattingUtils } from "@/lib/utils/formatting.utils";
import {
  CPException,
  CPParkAvailability,
  CPParkDirection,
  CPParkPrice,
  HourlyPriceRequest,
  Location,
  ParkAvailabilityRequest,
  ParkPriceRequest,
} from "@clicknpark/sdk";
import CPParkV3 from "@clicknpark/sdk/dist/types/entities/v3/parks/CPParkV3";
import { TravelMode } from "@googlemaps/google-maps-services-js";
import { QueryKey, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

// Get Park by ID (GET)
// ========================================

export const GET_PARK_QUERY_KEY = "park";

type GetParkData = {
  parkId?: string;
};

export const getPark = async ({ parkId }: GetParkData): Promise<CPParkV3> => {
  if (!parkId) throw new Error("parkId is required");
  return await clicknpark.parksV3.getById(parkId);
};

export const useGetParkQuery = (data: GetParkData) => {
  return useQuery<CPParkV3, CPException, CPParkV3, QueryKey>({
    queryKey: [GET_PARK_QUERY_KEY, data.parkId],
    queryFn: () => getPark(data),
    enabled: !!data.parkId,
    retry,
  });
};

// Get Park Directions from provided location (GET)
// ========================================

export const GET_PARK_DIRECTIONS_QUERY_KEY = "park-directions";

type GetParkDirectionsData = {
  parkId?: string;
  location?: Location;
};

export const getParkDirections = async ({ parkId, location }: GetParkDirectionsData): Promise<CPParkDirection> => {
  if (!parkId) throw new Error("parkId is required");
  if (!location) throw new Error("location is required");
  return await clicknpark.parks.getDirection(parkId, {
    originLatitude: location.latitude,
    originLongitude: location.longitude,
    mode: TravelMode.walking,
  });
};

export const useGetParkDirectionsQuery = (data: GetParkDirectionsData) => {
  return useQuery<CPParkDirection, CPException, CPParkDirection, QueryKey>({
    queryKey: [GET_PARK_DIRECTIONS_QUERY_KEY, data.parkId],
    queryFn: () => getParkDirections(data),
    enabled: !!data.parkId && !!data.location,
    retry,
  });
};

// Get Park Price (GET)
// ========================================

export const GET_PARK_PRICE_QUERY_KEY = "park-price";

type GetParkPriceData = {
  parkId?: string;
  request: ParkPriceRequest;
  options?: { enabled?: boolean };
};

export const getParkPrice = async ({ parkId, request }: GetParkPriceData): Promise<CPParkPrice> => {
  if (!parkId) throw new Error("parkId is required");
  return await clicknpark.parks.getPrice(parkId, request);
};

export const useGetParkPriceQuery = (data: GetParkPriceData) => {
  const enabled =
    typeof data?.options?.enabled !== "undefined"
      ? // if options.enabled is provided, use it
        data.options.enabled && !!data.parkId && !!data.request
      : // otherwise, use parkId and request
        !!data.parkId && !!data.request;

  return useQuery<CPParkPrice, CPException, CPParkPrice, QueryKey>({
    queryKey: [GET_PARK_PRICE_QUERY_KEY, data.parkId, data.request.startDate, data.request.endDate],
    queryFn: () => getParkPrice(data),
    staleTime: 0, // The data will never be considered fresh and will always be refetched
    enabled,
    retry,
  });
};

// Get Park Slot Prices (GET)
// ========================================

export const GET_PARK_SLOT_PRICES_QUERY_KEY = "park-slot-prices";

export type SlotOption = {
  slot: number;
  request: HourlyPriceRequest;
};

export type Slot = {
  durationInHours: number;
  formattedPrice: string;
  currency?: string;
  price: number;
};

type GetParkSlotPricesData = {
  parkId?: string;
  requests?: SlotOption[];
};

export const useGetParkSlotPricesQuery = ({ parkId, requests }: GetParkSlotPricesData) => {
  return useQuery<Slot[], CPException, Slot[], QueryKey>({
    queryKey: [GET_PARK_SLOT_PRICES_QUERY_KEY],
    queryFn: async () => {
      if (!parkId) throw new Error("parkId is required");
      if (!requests) throw new Error("requests is required");
      const promises = requests.map((request) =>
        getParkPrice({ parkId, request: request.request }).then((result) => {
          return {
            durationInHours: request.slot,
            currency: result.currency,
            price: result.total,
            formattedPrice: NumberFormattingUtils.formatAmountWithoutCurrency(result.total, i18next.language, result.currency),
          };
        })
      );

      return await Promise.all(promises);
    },
    staleTime: 0, // The data will never be considered fresh and will always be refetched
    enabled: !!parkId && !!requests,
    retry,
  });
};

// Get Park Availability (GET)
// ========================================

export const GET_PARK_AVAILABILITY_QUERY_KEY = "park-availability";

type GetParkAvailabilityData = {
  parkId?: string;
  request: ParkAvailabilityRequest;
};

export const getParkAvailability = async ({ parkId, request }: GetParkAvailabilityData): Promise<CPParkAvailability> => {
  if (!parkId) throw new Error("parkId is required");
  return await clicknpark.parks.getAvailability(parkId, request);
};

export const useGetParkAvailabilityQuery = (data: GetParkAvailabilityData) => {
  return useQuery<CPParkAvailability, CPException, CPParkAvailability, QueryKey>({
    queryKey: [GET_PARK_AVAILABILITY_QUERY_KEY, data.parkId, data.request.startDate, data.request.endDate],
    queryFn: () => getParkAvailability(data),
    staleTime: 0, // The data will never be considered fresh and will always be refetched
    enabled: !!data.parkId,
    retry,
  });
};

// Legacy list parks (GET)
// ========================================

export const LIST_PARKS_VENUE_QUERY_KEY = "parks-venue";

export interface ListParksForVenueRequest {
  venueId?: string;
  latitude?: number;
  longitude?: number;
  startTime: string;
  endTime: string;
  locale?: string;
}

export interface VenuePark {
  objectId: string;
  location: {
    lat: number;
    lng: number;
  };
  address: {
    streetNumber: string;
    street: string;
    city: string;
    state: string;
    country: string;
    countryAsISO: string;
    currencyForCountry: string;
    zip: string;
    line1: string;
  };
  photos: Array<{
    name: string;
    id: string;
    files: {
      fullSize: {
        path: string;
        size: {
          width: number;
          height: number;
        };
      };
      thumbnail: {
        path: string;
        size: {
          width: number;
          height: number;
        };
      };
      thumbnailHalf: {
        path: string;
        size: {
          width: number;
          height: number;
        };
      };
    };
  }>;
  spaceQuantity: number;
  distanceFromSearch: number;
  directions: {
    walking: {
      distance: {
        text: string;
        value: number;
      };
      duration: {
        text: string;
        value: number;
      };
      points: Array<Array<number>>;
    };
  };
  isAvailable: boolean;
  baseAmount: {
    number: number;
    formattedToLocale: string;
  };
  calculatedAmount: {
    number: number;
    formattedToLocale: string;
  };
  scheduleBlocks?: Array<{
    type: string;
    pricing: number;
    startsOn: string;
    endsOn: string;
  }>;
  accessType: string;
  hasDoorDevice: boolean;
  doorDevices: Array<any>;
}

export const listParksForVenue = async (request: ListParksForVenueRequest): Promise<VenuePark[]> => {
  if (!request.venueId) throw new Error("Venue ID is required");
  if (!request.latitude || !request.longitude) throw new Error("Latitude and Longitude are required");
  if (!request.locale) throw new Error("Locale is required");

  const params: {
    lat: number;
    lng: number;
    radius: number;
    limit: number;
    startTime: string;
    endTime: "duration" | string;
    availability?: "available" | "unavailable" | "all";
    filterByAvailability: boolean;
    subscription: boolean;
    locale: string;
    venueId: string;
  } = {
    lat: request.latitude,
    lng: request.longitude,
    radius: 2,
    limit: 60,
    locale: request.locale,
    startTime: dayjs(request.startTime).isToday() ? "now" : request.startTime,
    endTime: request.endTime,
    availability: "all",
    filterByAvailability: true,
    subscription: false,
    venueId: request.venueId,
  };

  const response = await axiosClient.get(`/parks`, { params });

  return response.data.items as VenuePark[];
};

export const useListParksForVenueQuery = (request: ListParksForVenueRequest) => {
  return useQuery<VenuePark[], CPException, VenuePark[], QueryKey>({
    queryKey: [LIST_PARKS_VENUE_QUERY_KEY, request.venueId, request.startTime, request.endTime],
    queryFn: () => listParksForVenue(request),
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled: !!request.venueId,
  });
};