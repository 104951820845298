import { ErrorBoundary } from "@/error";
import { removeAuthTokenHeader, setAuthTokenHeader } from "@/lib/services/config/api";
import { configureSentry } from "@/lib/services/sentry.services";
import { keys } from "@/lib/utils/storage.utils";
import { removeTrailingSlash } from "@/lib/utils/string.utils";
import UtmUtils from "@/lib/utils/utm.utils";
import Account from "@/routes/account/index";
import Profile from "@/routes/account/profile";
import Reservations from "@/routes/account/reservations/index";
import Reservation from "@/routes/account/reservations/reservation";
import PromoCode from "@/routes/promo-code";
import Search from "@/routes/search/index";
import SelectEvent from "@/routes/search/select-event";
import SelectParkForEvent from "@/routes/search/select-park-for-event";
import SelectParkForParkGroup from "@/routes/search/select-park-for-park-group";
import SelectTime from "@/routes/search/select-time";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import posthog from "posthog-js";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, createBrowserRouter, useLocation, useNavigate, useSearchParams } from "react-router-dom";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

configureSentry();

const authToken = Cookies.get(keys.AUTH_TOKEN);
const bifrostEmail = Cookies.get(keys.BIFROST_EMAIL);

if (authToken) {
  setAuthTokenHeader(authToken);
} else {
  removeAuthTokenHeader();
}

function App() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      window.location.href = "https://clicknpark.com";
      return;
    } else if (removeTrailingSlash(location.pathname) === "/account") {
      navigate("/account/reservations", { replace: true });
      return;
    }
  }, [location.pathname, navigate]);

  /* Capture pageview */
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  /* Save UTM params */
  const [searchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source") || undefined;
  const utm_medium = searchParams.get("utm_medium") || undefined;
  const utm_campaign = searchParams.get("utm_campaign") || undefined;
  const utm_content = searchParams.get("utm_content") || undefined;
  const utm_term = searchParams.get("utm_term") || undefined;

  useEffect(() => {
    UtmUtils.saveUtmParams({
      source: utm_source,
      medium: utm_medium,
      campaign: utm_campaign,
      content: utm_content,
      term: utm_term,
    });
  }, [utm_source, utm_medium, utm_campaign, utm_content, utm_term]);

  if (bifrostEmail && authToken) {
    return (
      <div>
        <div className="bg-rapide-600 py-3 text-center text-sm font-semibold text-white">{t("loggedInAs", { email: bifrostEmail })}</div>
        <Outlet />
      </div>
    );
  }

  return <Outlet />;
}

export default sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Search />,
        children: [
          {
            path: "/venue/:venueId",
            element: <SelectEvent />,
          },
          {
            path: "/venue/:venueId/:eventId",
            element: <SelectParkForEvent />,
          },
          {
            path: "/park-group/:parkGroupId",
            element: <SelectParkForParkGroup />,
          },
          {
            path: "/park/:parkId",
            element: <SelectTime />,
          },
        ],
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/account",
        element: <Account />,
        children: [
          {
            path: "/account/reservations",
            element: <Reservations />,
          },
          {
            path: "/account/reservations/:reservationId",
            element: <Reservation />,
          },
          {
            path: "/account/profile",
            element: <Profile />,
          },
        ],
      },
      {
        path: "/promo-code",
        element: <PromoCode />,
      },
    ],
  },
]);
