import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { getRoute } from "@/lib/configs/routes.config";
import cn from "@/lib/utils/cn.utils";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  loading?: boolean;
  venueName?: string;
  eventName?: string;
  start: string;
  end: string;
}

export default function HeaderEvent({ loading, venueName, eventName, start, end }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation(["search"]);

  const params = useParams<{ venueId: string; eventId: string }>();
  const venueId = params.venueId;
  const eventId = params.eventId;

  const back = () => {
    if (venueId && eventId) navigate(getRoute("SEARCH_SELECT_EVENT", { venueId, eventId }));
  };

  return (
    <header className="p-5 py-10 bg-simple-900 text-white">
      <Button variant="link" onClick={back} className="mb-5 text-xs">
        <ArrowLeft className="w-3 h-3 mr-0.5" />
        {t("park:back")}
      </Button>

      <div>
        {loading ? (
          <div className="space-y-2">
            <Skeleton className="w-3/4 h-3 bg-rapide-50/5" />
            <Skeleton className="w-full h-3 bg-rapide-50/5" />
            <Skeleton className="w-full h-3 bg-rapide-50/5" />
          </div>
        ) : (
          <>
            {venueName ? <div className="mb-1 text-rapide-600 text-sm font-semibold">{t("search:parkingForYourEvent")}</div> : null}
            <div className={cn("text-3xl block font-heading font-extrabold")}>{eventName}</div>
          </>
        )}
      </div>

      <div className="mt-2 flex items-center text-white">
        {loading ? (
          <div className="flex space-x-1.5">
            <Skeleton className="w-16 h-3 bg-rapide-50/5" />
            <Skeleton className="w-12 h-3 bg-rapide-50/5" />
          </div>
        ) : (
          <>
            <span className="text-sm font-medium">{start}</span>
            <ArrowRight className="w-3.5 h-3.5 mx-1" />
            <span className="text-sm font-medium">{end}</span>
          </>
        )}
      </div>
    </header>
  );
}
