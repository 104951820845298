import NavButton, { isNavButton, NavButtonProps } from "@/components/layout/buttons/nav-button";
import NavLink, { isNavLink, NavLinkProps } from "@/components/layout/buttons/nav-link";
import Logo from "@/components/layout/logo";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import cn from "@/lib/utils/cn.utils";
import { Menu, X } from "lucide-react";
import { Fragment, useState } from "react";

export type Menu = { items: (NavLinkProps | NavButtonProps)[] };
export type NavProps = { menus: Menu[] };

export default function HeaderNav({ menus }: NavProps) {
  const { language } = useLanguageUtils();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const openMobileMenu = () => setMobileMenuOpen(true);
  const closeMobileMenu = () => setMobileMenuOpen(false);
  const hideInDesktop = "xl:hidden";

  return (
    <Fragment>
      <nav className="flex flex-grow justify-end pl-4">
        <div
          className={cn(
            "w-full items-stretch justify-between xl:static xl:flex xl:p-0",
            // Mobile menu
            "fixed bottom-0 left-0 right-0 top-0 z-[5000] block h-full bg-white p-4",
            mobileMenuOpen ? "block" : "hidden xl:flex" // make sure to make the menu visible at all times on desktop
          )}
        >
          <Logo {...{ language: language as "fr" | "en", link: "/" }} className="block pt-1 xl:hidden" />

          {menus.map((menu, index) => {
            if (menu.items.length === 0) return <ul key={index} />;

            return (
              <ul
                key={index}
                className={cn(
                  "items-stretch",

                  // Mobile menu
                  "mb-6 block pt-6 xl:mb-0 xl:flex xl:pt-0"
                )}
              >
                {menu.items.map((item, index) => (
                  <li key={index} className="border-b border-b-rapide-100 last:border-b-0 xl:border-0">
                    {isNavLink(item) ? <NavLink {...item} onClick={closeMobileMenu} /> : null}
                    {isNavButton(item) ? (
                      <NavButton
                        {...item}
                        onClick={() => {
                          item?.onClick?.();
                          closeMobileMenu();
                        }}
                      />
                    ) : null}
                  </li>
                ))}
              </ul>
            );
          })}

          <button onClick={closeMobileMenu} className={cn("absolute right-5 top-5 justify-self-end", hideInDesktop)}>
            <X />
          </button>
        </div>

        <button className={cn("justify-self-end", hideInDesktop)} onClick={openMobileMenu}>
          <Menu />
        </button>
      </nav>
    </Fragment>
  );
}
