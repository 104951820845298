import { useEffect, useState } from "react";

function getMobileOperatingSystem() {
  if (typeof window === "undefined") return "unknown";

  // @ts-ignore
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export default function useDetectOs() {
  const [os, setOs] = useState<"Android" | "iOS" | "unknown" | "Windows Phone">(
    getMobileOperatingSystem()
  );
  const [isAndroidOrIOS, setIsAndroidOrIOS] = useState(false);

  useEffect(() => {
    const detectedOS = getMobileOperatingSystem();

    setIsAndroidOrIOS(detectedOS === "Android" || detectedOS === "iOS");
    setOs(detectedOS);
  }, []);

  return { os, isAndroidOrIOS };
}
