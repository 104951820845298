import { useGetParkQuery } from "@/lib/services/parks.services";
import SelectParkTimeContext from "@/lib/store/contexts/select-time.context";
import { useContext } from "react";

export default function PrivateAccessMessage() {
  const context = useContext(SelectParkTimeContext);
  const parkId = context?.parkId;

  const parkQuery = useGetParkQuery({ parkId });
  const park = parkQuery.data;

  if (!park?.isPrivateAccess) {
    return null;
  }

  return <div className="bg-rapide-600 text-white text-center p-5">{park?.privateAccessMessage || ""}</div>;
}
