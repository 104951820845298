import FieldDatetimepicker from "@/components/ui/custom-form-ui/fields/field-date-time-picker";
import TimePickerSelect from "@/components/ui/custom-form-ui/time-picker/time-picker-select";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import useDatetimepicker from "@/lib/hooks/use-datetimepicker";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { useGetParkPriceQuery } from "@/lib/services/parks.services";
import SelectParkTimeContext from "@/lib/store/contexts/select-time.context";
import { NumberFormattingUtils } from "@/lib/utils/formatting.utils";
import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function DurationCustom() {
  const { t } = useTranslation(["search", "park"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { language } = useLanguageUtils();

  const context = useContext(SelectParkTimeContext);
  const parkId = context?.parkId;
  const start = context.start;
  const end = context.end;

  const { startDate, endDate, minStartDate, minEndDate, handleStartDateChange, handleEndDateChange } = useDatetimepicker({
    initialStart: dayjs(start),
    initialEnd: dayjs(end),
  });

  useEffect(() => {
    setSearchParams(
      {
        ...Object.fromEntries(searchParams.entries()),
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      },
      { replace: true }
    );
  }, [startDate, endDate]);


  const getParkPriceQuery = useGetParkPriceQuery({ parkId, request: { startDate: start, endDate: end } });
  const price = getParkPriceQuery.data;

  return (
    <div className="md:flex justify-between items-center md:space-x-4 pb-3">
      <div className="md:flex justify-between items-center md:space-x-2 md:space-y-0 space-y-2 flex-1">
        <div className="flex-1">
          <Popover>
            <PopoverTrigger asChild>
              <button className="p-3 pt-2 flex items-center hover:bg-silver-900/50 cursor-pointer text-left border rounded-lg w-full focus:ring-2 hover:shadow-md border-rapide-400 hover:border-rapide-600 ring-rapide-600 ring-offset-2 outline-none transition-all">
                <TimePickerSelect label={t("search:arriveAfter")} date={startDate} />
              </button>
            </PopoverTrigger>
            <PopoverContent className="w-[320px] ml-5">
              <FieldDatetimepicker
                date={startDate}
                minDate={minStartDate}
                onSelect={handleStartDateChange}
                minTimeApplies={startDate.isSame(dayjs(), "day")}
                timeLabel={t("search:arriveAfter")}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex-1">
          <Popover>
            <PopoverTrigger asChild>
              <button className="p-3 pt-2 flex items-center hover:bg-silver-900/50 cursor-pointer text-left border rounded-lg w-full focus:ring-2 hover:shadow-md border-rapide-400 hover:border-rapide-600 ring-rapide-600 ring-offset-2 outline-none transition-all">
                <TimePickerSelect label={t("search:leaveBefore")} date={endDate} />
              </button>
            </PopoverTrigger>
            <PopoverContent className="w-[320px]">
              <FieldDatetimepicker date={endDate} minDate={minEndDate} onSelect={handleEndDateChange} timeLabel={t("search:leaveBefore")} />
            </PopoverContent>
          </Popover>
        </div>
      </div>

      <div className="md:-mt-2 mt-2 md:text-left text-center md:space-x-0 space-x-1">
        <span className="md:text-sm text-md">{t("search:bookingPrice")}</span>
        {price && language ? (
          <strong className="md:block">{NumberFormattingUtils.formatAmountWithoutCurrency(price.total, language, price.currency)}</strong>
        ) : null}
      </div>
    </div>
  );
}
