import Spinner from "@/components/ui/spinner";
import cn from "@/lib/utils/cn.utils";
import { Slot } from "@radix-ui/react-slot";
import * as React from "react";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: "primary" | "secondary" | "destructive";
  variant?: "default" | "light" | "subtle" | "outline" | "link";
  size?: "sm" | "md" | "lg";
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
  asChild?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = "default",
      color = "primary",
      size = "md",
      asChild = false,
      loading = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";

    let sharedClassName =
      "shadow-sm shadow-black/10 hover:shadow-md hover:shadow-black/20 focus:shadow-none inline-flex justify-center items-center whitespace-nowrap rounded-md text-base font-medium transition disabled:pointer-events-none disabled:opacity-50 focus:outline focus:outline-2 focus:outline-offset-2";

    let variantClassName = "";

    switch (variant) {
      case "default":
        if (color === "primary") {
          variantClassName = "bg-rapide-600 hover:bg-rapide-600/90 text-white focus:outline-rapide-600";
        } else if (color === "secondary") {
          variantClassName =
            "bg-white shadow-black/10 hover:shadow-black/20 hover:bg-gray-50 focus:outline-gray-300 text-gray-900 ring-1 ring-inset ring-gray-200";
        } else if (color === "destructive") {
          variantClassName = "bg-attention-700 hover:bg-attention-600 focus:outline-attention-700 text-white";
        }
        break;

      case "light":
        if (color === "primary") {
          variantClassName = "shadow-none hover:shadow-none bg-rapide-600/10 hover:bg-rapide-600/15 focus:outline-rapide-600/15 text-simple-950";
        } else if (color === "secondary") {
          variantClassName = "shadow-none hover:shadow-none bg-gray-200/50 hover:bg-gray-300 focus:outline-gray-200 text-gray-900";
        } else if (color === "destructive") {
          variantClassName =
            "shadow-none hover:shadow-none bg-attention-600/20 hover:bg-attention-600/40 focus:outline-attention-600 text-attention-600 text-attention-600";
        }
        break;

      case "subtle":
        if (color === "primary") {
          variantClassName = "shadow-none hover:shadow-none bg-none hover:bg-rapide-600/10 focus:outline-rapide-600/15 text-simple-950";
        } else if (color === "secondary") {
          variantClassName = "shadow-none hover:shadow-none bg-none hover:bg-gray-200/50 focus:outline-gray-200 text-gray-900";
        } else if (color === "destructive") {
          variantClassName = "shadow-none hover:shadow-none bg-none hover:bg-attention-600/20 focus:outline-attention-600 text-attention-600";
        }
        break;

      case "outline":
        if (color === "primary") {
          variantClassName =
            "shadow-none hover:shadow-none bg-none border border-simple-950 hover:bg-rapide-600/10 focus:outline-rapide-600/15 text-simple-950";
        } else if (color === "secondary") {
          variantClassName = "shadow-none hover:shadow-none bg-none border border-gray-300 hover:bg-gray-300/50 focus:outline-gray-200 text-gray-900";
        } else if (color === "destructive") {
          variantClassName =
            "shadow-none hover:shadow-none bg-none border border-attention-600 hover:bg-attention-600/20 focus:outline-attention-600 text-attention-600";
        }
        break;

      case "link":
        if (color === "primary") {
          variantClassName =
            "shadow-none hover:shadow-none focus:outline-none focus:translate-y-0 underline-offset-4 hover:underline text-rapide-600 focus:no-underline focus:text-rapide-600";
        } else if (color === "secondary") {
          variantClassName =
            "shadow-none hover:shadow-none focus:outline-none focus:translate-y-0 underline-offset-4 hover:underline text-slate-500 focus:no-underline focus:text-gray-800";
        } else if (color === "destructive") {
          variantClassName =
            "shadow-none hover:shadow-none focus:outline-none focus:translate-y-0 underline-offset-4 hover:underline text-attention-600 focus:no-underline focus:text-attention-600";
        }
        break;
    }

    let sizeClassName = "";

    switch (size) {
      case "sm":
        sizeClassName = "min-h-8 px-2.5 py-1 text-sm";
        if (variant === "link") sizeClassName = "px-0 py-0 text-xs underline-offset-4 hover:underline";
        break;
      case "md":
        sizeClassName = "min-h-10 px-4 py-2 text-base";
        if (variant === "link") sizeClassName = "px-0 py-0 text-sm underline-offset-4 hover:underline";
        break;
      case "lg":
        sizeClassName = "min-h-12 px-8 py-4 text-md";
        if (variant === "link") sizeClassName = "px-0 py-0 text-sm underline-offset-4 hover:underline";
        break;
    }

    let spinnerColor: "attention" | "white" | "gray" | "red" = "white";

    switch (color) {
      case "primary":
        if (variant === "default") {
          spinnerColor = "white";
        } else {
          spinnerColor = "attention";
        }
        break;
      case "secondary":
        spinnerColor = "gray";
        break;
      case "destructive":
        if (variant === "default") {
          spinnerColor = "white";
        } else {
          spinnerColor = "red";
        }
        break;
    }

    return (
      <Comp
        className={cn(
          sharedClassName,
          variantClassName,
          sizeClassName,
          className
        )}
        ref={ref}
        {...props}
      >
        {loading ? (
          <Spinner size={size} color={spinnerColor} />
        ) : (
          props.children
        )}
      </Comp>
    );
  }
);

Button.displayName = "Button";

export { Button };
