import Duration from "@/components/containers/search/sidebar/booking/duration";
import HeaderPark from "@/components/containers/search/sidebar/header/header-park";
import ParkPhotos from "@/components/containers/search/sidebar/park/photos";
import ParkSection from "@/components/containers/search/sidebar/park/section";
import { Button } from "@/components/ui/button";
import Map, { CPMarker } from "@/components/ui/map";
import { Separator } from "@/components/ui/separator";
import { TrackingServices } from "@/lib/services/tracking.services";
// import Error from "@/components/ui/Error";
import ErrorAlert from "@/components/containers/search/sidebar/error";
import ParkFeatures from "@/components/containers/search/sidebar/park/features";
import PrivateAccessMessage from "@/components/containers/search/sidebar/private-access";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import Spinner from "@/components/ui/spinner";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { useGetEventQuery } from "@/lib/services/events.services";
import { handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { useGetParkQuery } from "@/lib/services/parks.services";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "@/lib/store/configs/defaut-search-params";
import SelectParkTimeContext from "@/lib/store/contexts/select-time.context";
import { useMapStore } from "@/lib/store/map.store";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BiStreetView } from "react-icons/bi";
import { useParams, useSearchParams } from "react-router-dom";

function getNormalizedStart(start: string): Dayjs {
  // start should be now or later
  return dayjs(start).isBefore(dayjs(), "seconds") ? dayjs() : dayjs(start);
}

function getNormalizedEnd(start: string, end: string): Dayjs {
  // if end is before start, set end to start + 1 hour
  return dayjs(end).isBefore(dayjs(start)) ? dayjs(start).add(1, "hour") : dayjs(end);
}

export default function SelectTime() {
  const { t } = useTranslation(["park"]);
  const { language } = useLanguageUtils();

  const params = useParams<{ parkId: string }>();
  const parkId = params.parkId;

  const [searchParams, setSearchParams] = useSearchParams();
  const venueId = searchParams.get("vid") as string | undefined;
  const eventId = searchParams.get("eid") as string | undefined;
  const start = (searchParams.get("start") || DEFAULT_START_DATE) as string;
  const end = (searchParams.get("end") || DEFAULT_END_DATE) as string;

  const [timeValidationComplete, setTimeValidationComplete] = useState<boolean>(false);
  const { markers, center, shouldRecenter, hoveredMarkerIndex } = useMapStore();

  useEffect(() => {
    setSearchParams(
      {
        ...Object.fromEntries(searchParams.entries()),
        start: getNormalizedStart(start).toISOString(),
        end: getNormalizedEnd(start, end).toISOString(),
      },
      { replace: true }
    );

    setTimeValidationComplete(true);
  }, [start, end]);

  const getEventQuery = useGetEventQuery({ venueId, eventId, locale: language });
  const event = getEventQuery.data;

  const parkQuery = useGetParkQuery({ parkId });
  const park = parkQuery.data;

  const isLoading = parkQuery.isLoading || getEventQuery.isLoading;

  const { setMarkers, setCenter, recenter } = useMapStore();

  useEffect(() => {
    if (park) {
      const markers: CPMarker[] = [
        {
          id: park.id,
          type: "parking",
          label: "P",
          coordinates: {
            lat: park.location.latitude,
            lng: park.location.longitude,
          },
        },
      ];

      if (event) {
        markers.push({
          id: event.id,
          type: "destination",
          label: event.venue.name,
          coordinates: {
            lat: event.venue.location.latitude,
            lng: event.venue.location.longitude,
          },
        });
      }

      setMarkers(markers);
      if (markers.length === 1) setCenter(markers[0].coordinates);
      recenter();
    }
  }, [park, event]);

  useEffect(() => {
    if (!park) return;
    TrackingServices.trackParkView(park);
  }, [park]);

  if (isLoading || !timeValidationComplete) {
    return (
      <div className="md:min-h-full min-h-screen h-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  if (parkQuery.error) {
    return (
      <div className="md:min-h-full min-h-screen flex flex-col items-center justify-center text-md text-red-600 p-10">
        <ErrorAlert error={handleError(parkQuery.error)} />
        <a href="https://clicknpark.com">
          <Button className="mt-5">{t("park:backToHome")}</Button>
        </a>
      </div>
    );
  }

  const publicPhotos = park?.medias.filter((media) => park?.publicPhotosIds?.includes(media.id)) || [];
  const allowsStuddedTires = park?.features?.allowsStuddedTires;
  const allowsMultiUse = park?.features?.allowsMultiUse;

  return (
    <SelectParkTimeContext.Provider
      value={{
        parkId,
        venueId,
        eventId,
        start,
        end,
      }}
    >
      <div className="md:min-h-full min-h-screen bg-white">
        <Helmet>
          <title>{t("parkingAt", { location: park?.address.line1 || "" })} | clicknpark</title>
        </Helmet>

        <PrivateAccessMessage />

        <HeaderPark>
          <ParkFeatures features={park?.features} type={park?.type} />
        </HeaderPark>

        <ParkSection title={t("park:selectDuration")}>
          <Duration />
        </ParkSection>

        <Separator />

        {!allowsStuddedTires || !allowsMultiUse ? (
          <ParkSection title={t("park:restrictions")}>
            <div className="space-y-2">
              {!allowsStuddedTires ? (
                <Alert
                  variant="noBorder"
                  size="xs"
                  customIcon={
                    <div className="flex-shrink-0 flex items-center justify-center w-16">
                      <img src="/images/no-studded-tires.svg" alt={t("park:features.disallowsStuddedTires")} className="w-12" />
                    </div>
                  }
                >
                  <AlertTitle>{t("park:features.disallowsStuddedTires")}</AlertTitle>
                  <AlertDescription>{t("park:features.disallowsStuddedTiresDescription")}</AlertDescription>
                </Alert>
              ) : null}

              {!allowsMultiUse ? (
                <Alert
                  variant="noBorder"
                  size="xs"
                  customIcon={
                    <div className="flex-shrink-0 flex items-center justify-center w-16">
                      <img src="/images/no-multi-use.svg" alt={t("park:features.disallowsStuddedTires")} className="w-15 ml-1" />
                    </div>
                  }
                >
                  <AlertTitle>{t("park:features.disallowsMultiUse")}</AlertTitle>
                  <AlertDescription>{t("park:features.disallowsMultiUseDescription")}</AlertDescription>
                </Alert>
              ) : null}
            </div>
          </ParkSection>
        ) : null}

        <Separator />

        <div className="block md:hidden">
          <ParkSection title={t("park:parkLocation")}>
            <div className="w-full h-[250px] rounded-lg overflow-hidden">
              <Map
                {...{
                  markers,
                  center,
                  shouldRecenter,
                  hoveredMarkerIndex,
                  zoom: 14,
                }}
              />
            </div>
          </ParkSection>

          <Separator />
        </div>

        <ParkSection title={t("park:photos")} padding={["left", "top", "bottom"]}>
          <ParkPhotos sources={publicPhotos} />
          <Button variant="link" className="mt-3" asChild>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${park?.location.latitude},${park?.location.longitude}&heading=-45&pitch=38&fov=80/`}
            >
              <BiStreetView className="w-5 h-5 mr-2" />
              {t("park:openStreetsView")}
            </a>
          </Button>
        </ParkSection>
      </div>
    </SelectParkTimeContext.Provider>
  );
}
