import { Button } from "@/components/ui/button";
import cn from "@/lib/utils/cn.utils";

export interface NavButtonProps {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  color?: "primary" | "secondary";
  size?: "sm" | "md" | "lg";
  stripStyles?: boolean;
}

export function isNavButton(props: any): props is NavButtonProps {
  return typeof props.label === "string" && typeof props.onClick === "function";
}

export default function NavButton({ label, onClick, icon, color = "primary", size = "md", stripStyles = false }: NavButtonProps) {
  return (
    <div className="flex h-full items-center">
      {stripStyles ? (
        <button
          {...{
            onClick,
            className: "flex h-full items-center gap-2 hover:text-rapide-600 xl:px-4 py-3",
          }}
        >
          {icon}
          {label}
        </button>
      ) : (
        <Button
          {...{
            onClick,
            color,
            size,
          }}
          className={cn(
            "flex items-center gap-2 xl:ml-4",
            // Mobile menu
            "x:my-0 my-3"
          )}
        >
          {icon}
          {label}
        </Button>
      )}
    </div>
  );
}
