import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { getRoute } from "@/lib/configs/routes.config";
import { useGetParkQuery } from "@/lib/services/parks.services";
import SelectParkTimeContext from "@/lib/store/contexts/select-time.context";
import cn from "@/lib/utils/cn.utils";
import { ArrowLeft } from "lucide-react";
import { PropsWithChildren, useContext } from "react";
import { useTranslation } from "react-i18next";

export default function HeaderPark({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { t } = useTranslation(["park"]);

  const context = useContext(SelectParkTimeContext);
  const parkId = context?.parkId;
  const venueId = context?.venueId;
  const eventId = context?.eventId;

  const parkQuery = useGetParkQuery({ parkId });
  const park = parkQuery.data;

  const displayBack = venueId && eventId;
  const back = () => venueId && eventId && navigate(getRoute("SEARCH_SELECT_PARK_FOR_EVENT", { venueId, eventId }));

  return (
    <header className="p-5 py-10 bg-simple-900 text-white">
      <div>
        {displayBack ? (
          <Button variant="link" onClick={back} className="mb-5 text-xs">
            <ArrowLeft className="w-3 h-3 mr-0.5" />
            {t("park:back")}
          </Button>
        ) : null}

        <div>
          {parkQuery.isLoading ? (
            <div className="space-y-2">
              <Skeleton className="w-3/4 h-3 bg-rapide-50/5" />
              <Skeleton className="w-full h-3 bg-rapide-50/5" />
            </div>
          ) : (
            <div className={cn("text-3xl block font-heading font-extrabold")}>{park?.address.line1}</div>
          )}
        </div>
      </div>

      {children}
    </header>
  );
}
