import FieldTimePicker from "@/components/ui/custom-form-ui/fields/field-time-picker";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { enUS, fr } from "date-fns/locale";
import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { DayPicker } from "react-day-picker";

dayjs.extend(isSameOrBefore);

interface FieldDatetimepickerProps {
  date: Dayjs;
  minDate: Dayjs;
  onSelect: (date: Dayjs) => void;
  minTimeApplies?: boolean; // For start date, min time only applies if the date is today
  timeLabel?: string;
}

export default function FieldDatetimepicker({ date, minDate, onSelect, minTimeApplies = true, timeLabel }: FieldDatetimepickerProps) {
  const { language } = useLanguageUtils();
  const isEN = language === "en";
  const datePickerLocale = isEN ? enUS : fr;
  const currentDate = date;

  return (
    <div>
      <DayPicker
        mode="single"
        selected={date.toDate()}
        fromMonth={date.toDate()}
        fromDate={minDate.toDate()}
        locale={datePickerLocale}
        onSelect={(date) => {
          if (!date) return;
          let newDateDayjs = dayjs(date);
          newDateDayjs = newDateDayjs.set("hour", currentDate.get("hour"));
          newDateDayjs = newDateDayjs.set("minute", currentDate.get("minute"));
          onSelect(newDateDayjs);
        }}
      />

      <div className="flex items-center justify-center space-x-2 bg-simple-900 p-5 rounded-lg ">
        {timeLabel ? <div className="font-bold text-base w-3/4 text-white">{timeLabel}</div> : null}
        <FieldTimePicker date={date} onSelect={onSelect} minDate={minDate} minTimeApplies={minTimeApplies} locale={language} />
      </div>
    </div>
  );
}
