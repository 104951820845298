import { DateUtils } from "@/lib/utils/date.utils";
import dayjs from "dayjs";

export const SLOTS = [1, 2, 4, 6, 8];
export const DEFAULT_SELECTED_SLOT_INDEX = 2;

export const DEFAULT_START_DATE = DateUtils.getClosestQuarter(
  dayjs()
).toISOString();

export const DEFAULT_END_DATE = DateUtils.getClosestQuarter(
  dayjs().add(SLOTS[DEFAULT_SELECTED_SLOT_INDEX], "hour")
).toISOString();

// Defaults to Montreal downtown
export const DEFAULT_LOCATION = {
  lat: 45.5017,
  lng: -73.5673,
};
