import SvgError from "@/components/svg/svg-error";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { contact } from "@/lib/configs/contact.config";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";

export function ErrorBoundary() {
  const error = useRouteError();
  const { t } = useTranslation("errors");
  const { language } = useLanguageUtils();

  const [handledError, setHandledError] = useState<HandledError | undefined>();

  useEffect(() => {
    setHandledError(handleError(error));
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white md:bg-silver-900">
      <Card className="md:p-12 flex flex-col md:items-center md:justify-center max-w-full shadow-none md:shadow-xl border-0 md:border">
        <SvgError strokeColor="#15287D" notchColor="#008FFF" width={102} height={100} />

        <h1 className="font-heading text-3xl font-extrabold mb-1 mt-4">{handledError?.formatted.title}</h1>
        <p>{handledError?.formatted.description}</p>

        {handledError?.message || handledError?.code ? (
          <div className="w-full pt-4 border-t border-t-silver-800 mt-6">
            <strong className="font-semibold block text-sm text-center">{t("errorBoundary.errorDetails")}</strong>
            <div className="bg-red-50 text-red-500 p-4 mt-4 rounded-md w-full border border-red-100 text-sm">
              {handledError?.code ? <pre>code: {handledError.code}</pre> : null}
              {handledError?.message ? <pre>message: {handledError.message}</pre> : null}
            </div>
          </div>
        ) : null}

        <div className="flex flex-col md:flex-row gap-4 flex-1 mt-4 w-full">
          <Button asChild>
            <a href={`mailto:${contact.helpEmail[language]}`} className="w-full">
              {t("errorBoundary.emailUs", { email: contact.helpEmail[language] })}
            </a>
          </Button>
          <Button asChild>
            <a href={`tel:${contact.helpPhone.unformatted}`} className="w-full">
              {t("errorBoundary.callUs", { phone: contact.helpPhone.formatted })}
            </a>
          </Button>
        </div>
      </Card>
    </div>
  );
}
