import { clicknpark } from "@/lib/services/config/api";
import queryClient from "@/lib/services/config/query-client";
import { CPException, CPUser, CPUserSettings } from "@clicknpark/sdk";
import { CPUserV3 } from "@clicknpark/sdk/dist/types/entities/v3/users/CPUserV3";
import { UpdateUserRequest, UpdateUserSettingsRequest } from "@clicknpark/sdk/dist/types/services/latest/user/UserServiceTypes";
import { useMutation, useQuery } from "@tanstack/react-query";

// Get logged in user (GET)
// ========================================

export const GET_USER_QUERY_KEY = "get-user";

export const getUser = async (): Promise<CPUserV3> => {
  return await clicknpark.user.getUser();
};

export const useGetUserQuery = (request: { enabled?: boolean } | void) => {
  return useQuery<CPUserV3, CPException, CPUserV3>({
    queryKey: [GET_USER_QUERY_KEY],
    queryFn: getUser,
    enabled: typeof request?.enabled === "boolean" ? request.enabled : true,
    retry: false,
  });
};

export const useLazyGetUserQuery = () => {
  return async (query: string) => {
    return await queryClient.fetchQuery({
      queryKey: ["lazyFindVehicleModels", query],
      queryFn: getUser,
    });
  };
};

// Update user (POST)
// ========================================

export const UPDATE_USER_MUTATION_KEY = "update-user";

export const updateUser = async (request: UpdateUserRequest): Promise<CPUser> => {
  return await clicknpark.user.updateUser(request);
};

export const useUpdateUserMutation = () => {
  return useMutation<CPUser, CPException, UpdateUserRequest>({
    mutationKey: [UPDATE_USER_MUTATION_KEY],
    mutationFn: updateUser,
  });
};

// Get user settings (GET)
// ========================================

export const GET_USER_SETTINGS_QUERY_KEY = "user-settings";

export const getUserSettings = async (): Promise<CPUserSettings> => {
  return await clicknpark.user.getUserSettings();
};

export const useGetUserSettingsQuery = ({ enabled }: { enabled: boolean }) => {
  return useQuery<CPUserSettings, CPException, CPUserSettings, [string]>({
    queryKey: [GET_USER_SETTINGS_QUERY_KEY],
    queryFn: getUserSettings,
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
};

// Update user settings (POST)
// ========================================

export const UPDATE_USER_SETTINGS_MUTATION_KEY = "update-user-settings";

export const updateUserSettings = async (request: UpdateUserSettingsRequest): Promise<CPUserSettings> => {
  return await clicknpark.user.updateUserSettings(request);
};

export const userUpdateUserSettingsMutation = () => {
  return useMutation<CPUserSettings, CPException, UpdateUserSettingsRequest, [CPUserSettings]>({
    mutationKey: [UPDATE_USER_SETTINGS_MUTATION_KEY],
    mutationFn: updateUserSettings,
  });
};

// Accept terms of service (POST)
// ========================================

export const ACCEPT_TOS_MUTATION_KEY = "accept-tos";

export const acceptTos = async (): Promise<void> => {
  await clicknpark.user.acceptTerms({
    version: "1-0-0",
  });
};

export const useAcceptTosMutation = () => {
  return useMutation<void, CPException>({
    mutationKey: [ACCEPT_TOS_MUTATION_KEY],
    mutationFn: acceptTos,
  });
};
