import HeaderEvent from "@/components/containers/search/sidebar/header/header-event";
import Park from "@/components/containers/search/sidebar/lists/tile/park-tile";
import Loader from "@/components/layout/loader";
import { getRoute } from "@/lib/configs/routes.config";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { useGetEventQuery } from "@/lib/services/events.services";
import { useGetParkGroupQuery } from "@/lib/services/park-groups.services";
import { VenuePark, useListParksForVenueQuery } from "@/lib/services/parks.services";
import { useMapStore } from "@/lib/store/map.store";
import { DateUtils } from "@/lib/utils/date.utils";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const getCheapestParkId = (parks?: VenuePark[]): string | undefined => {
  if (!parks) return undefined;
  const availableParks = parks.filter((p) => p.isAvailable);
  if (availableParks.length === 0) return undefined;
  return availableParks.reduce((prev: VenuePark, current: VenuePark) => {
    return prev.calculatedAmount.number < current.calculatedAmount.number ? prev : current;
  }).objectId;
};

const getClosestParkId = (parks?: VenuePark[]): string | undefined => {
  if (!parks) return undefined;
  const availableParks = parks.filter((p) => p.isAvailable);
  if (availableParks.length === 0) return undefined;
  return availableParks.reduce((prev: VenuePark, current: VenuePark) => {
    return prev.directions.walking.duration.value < current.directions.walking.duration.value ? prev : current;
  }).objectId;
};

export default function SelectParkForEvent() {
  const navigate = useNavigate();
  const { t } = useTranslation(["search"]);
  const { language } = useLanguageUtils();

  const params = useParams<{ venueId: string; eventId: string }>();
  const venueId = params.venueId;
  const eventId = params.eventId;



  const getParkGroupQuery = useGetParkGroupQuery({
    parkGroupId: venueId,
    locale: language,
  });

  const parkGroup = getParkGroupQuery.data;
  const getEventQuery = useGetEventQuery({ venueId, eventId, locale: language });
  const event = getEventQuery.data;

  const dayjsStart = useMemo(() => {
    if (event?.bufferedEventStart) {
   return dayjs(event.bufferedEventStart);
 } else {
   return dayjs();
 }
  }, [event, eventId]);

  const dayjsEnd = useMemo(() => {
    if (event?.bufferedEventEnd) {
      return dayjs(event.bufferedEventEnd);
    }
    return dayjs().add(1, "hour");
  }, [event, eventId]);

  const formattedStart = dayjsStart ? DateUtils.formatDate(dayjsStart, { locale: language }) : "";
  const formattedEnd = dayjsEnd
    ? dayjsEnd.isToday()
      ? DateUtils.formatDate(dayjsEnd, { locale: language, format: "HH:mm" })
      : DateUtils.formatDate(dayjsEnd, { locale: language })
    : "";

  const listParksForVenueQuery = useListParksForVenueQuery({
    venueId: venueId,
    latitude: parkGroup?.location.latitude,
    longitude: parkGroup?.location.longitude,
    startTime: dayjsStart.toISOString(),
    endTime: dayjsEnd.toISOString(),
    locale: language,
  });

  const parks = listParksForVenueQuery.data
    ?.sort((p1, p2) => p1.calculatedAmount.number - p2.calculatedAmount.number)
    .sort((p1, p2) => (p1.isAvailable ? 0 : 1) - (p2.isAvailable ? 0 : 1));

  const cheapestParkId = getCheapestParkId(parks);
  const closestParkId = getClosestParkId(parks);

  const { setMarkers, recenter, setHoveredMarkerIndex } = useMapStore();

  useEffect(() => {
    if (!parks || !parkGroup) return;

    setMarkers([
      {
        type: "destination",
        label: parkGroup.name,
        id: parkGroup.id,
        coordinates: {
          lat: parkGroup.location.latitude,
          lng: parkGroup.location.longitude,
        },
      },
      ...parks.map((park, index) => ({
        label: `P${++index}`,
        id: park.objectId,
        coordinates: {
          lat: park.location.lat,
          lng: park.location.lng,
        },
      })),
    ]);

    recenter();
  }, [parks, parkGroup]);

  useEffect(() => {
    listParksForVenueQuery.refetch();
  }, [language]);

  function selectPark(parkId: string) {
    if (!event?.bufferedEventStart || !event?.bufferedEventEnd || !eventId) return;

    navigate(
      getRoute("SEARCH_SELECT_TIME_FOR_EVENT", {
        parkId,
        eventId,
        venueId,
        start: event.bufferedEventStart,
        end: event.bufferedEventEnd,
      })
    );
  }

  return (
    <div className="relative">
      <HeaderEvent
        {...{
          loading: getParkGroupQuery.isLoading || listParksForVenueQuery.isLoading,
          venueName: parkGroup?.name,
          eventName: event?.name,
          start: formattedStart,
          end: formattedEnd,
        }}
      />

      {listParksForVenueQuery.isLoading ? (
        <section className="relative p-20">
          <Loader />
        </section>
      ) : (
        <section className="p-5 overflow-auto flex-1">
          <strong>{t("search:parkingNearLocation", { location: parkGroup?.name })}</strong>
          <ul>
            {parks?.map((park, index) => (
              <Park
                key={park.objectId + index}
                {...{
                  park,
                  parkGroup,
                  label: `P${++index}`,
                  isCheapest: park.objectId === cheapestParkId,
                  isClosest: park.objectId === closestParkId,
                  onClick: () => selectPark(park.objectId),
                  onMouseEnter: () => setHoveredMarkerIndex(index),
                  onMouseLeave: () => setHoveredMarkerIndex(),
                }}
              />
            ))}
          </ul>
        </section>
      )}
    </div>
  );
}
