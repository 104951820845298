import clsx from "clsx";

interface Props {
  children: React.ReactNode;
  widthClass?: string;
  className?: string;
}

export default function Wrapper({
  children,
  widthClass = "w-full",
  className,
}: Props) {
  return (
    <div
      className={clsx(
        "lg:max-w-screen-lg max-w-full sm:px-5 sm:py-12 px-0 py-0 mx-auto",
        className,
        widthClass
      )}
    >
      {children}
    </div>
  );
}
