interface Props {
  text: string;
  addBottomPadding?: boolean;
}

export default function InstructionText({ text, addBottomPadding }: Props) {
  const Content = () => <p className=" pt-1">{text}</p>;

  if (addBottomPadding) {
    return (
      <div className="pb-3">
        <Content />
      </div>
    );
  }

  return <Content />;
}
