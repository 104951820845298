import cn from "@/lib/utils/cn.utils";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const badgeVariants = cva(
  cn(
    "inline-flex items-center rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2",
    "dark:focus:ring-slate-300 cursor-default"
  ),
  {
    variants: {
      size: {
        xs: "text-[10px] px-2 py-1 md:text-xs",
        sm: "text-[10px] px-2 py-1 md:text-xs",
        md: "text-[11px] px-2 py-1 md:text-xs",
        lg: "text-sm px-2.5 py-2 md:text-sm",
      },
      variant: {
        default: "border border-slate-200 bg-white text-black shadow-sm",
        secondary: "border-transparent bg-slate-100 text-slate-900 dark:bg-slate-800 dark:text-slate-50",
        neutral: "border-transparent bg-rapide-200 text-slate-900 dark:bg-slate-800 dark:text-slate-50",
        success: "border-transparent bg-green-600 text-slate-50 shadow dark:bg-green-900 dark:text-slate-50",
        destructive: "border-transparent bg-red-500 text-slate-50 shadow dark:bg-red-900 dark:text-slate-50",
        outline: "border border-simple-900 text-slate-950 dark:text-slate-50",
        attention: "border-transparent bg-attention-700 text-slate-50 shadow dark:bg-attention-900 dark:text-slate-50",
        outlineReverse: "border border-white text-white dark:text-slate-50",
      },
    },
    defaultVariants: {
      size: "md",
      variant: "default",
    },
  }
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  size?: "xs" | "sm" | "md" | "lg";
}

function Badge({ className, size = "md", variant, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };

