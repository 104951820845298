import { contact } from "@/lib/configs/contact.config";
import { Language } from "@/lib/hooks/use-language-utils";
import { logger } from "@/lib/services/debug/logger.services";
import { CPException } from "@clicknpark/sdk";
import i18next from "i18next";
import { isErrorLike, serializeError } from "serialize-error";

/* Constants */

// Default number of retry attempts
const DEFAULT_RETRY_COUNT = 3;

// List of error codes that should prevent retrying
const PREVENT_RETRY_LIST: number[] = [101];

/*
  Error scopes:
  - CPException: Clicknpark SDK Error
  - Error: JavaScript Error (e.g. TypeError, ReferenceError)
  - AppError: App Specific Error that gets thrown by the application (e.g. validation error)
  - HandledError: Final error object that gets returned to the user with a formatted title and description, if available
*/

export const AppError = class AppError extends Error {
  code: string;
  constructor(message: string, code: string) {
    super(message);
    this.name = "AppError";
    this.code = code;
  }
};

export interface HandledError {
  name?: string;
  code: string;
  handled: boolean;
  message: string;
  formatted: { title: string; description: string };
}

/*
  Retry logic:
  - Prevents retrying on specific error codes
  - Calls an optional callback function before retrying
  - Returns true if the failure count is less than the retry count

  How to use:
  - Add the retry logic to the error handler inside the queryClient (service files)
*/

export const retry = (
  failureCount: number,
  error: unknown,
  extra?: {
    callback?: () => void; // Improved callback typing
    retryCount?: number;
  }
): boolean => {
  if (error instanceof CPException) {
    if (PREVENT_RETRY_LIST.includes(error.code)) {
      return false;
    }
  }

  if (extra?.callback) {
    extra.callback();
  }

  return failureCount < (extra?.retryCount || DEFAULT_RETRY_COUNT);
};

/* Helper function to format errors */
const formatError = (code: string, message: string): HandledError => {
  if (i18next.exists(`errors:${code}`)) {
    return {
      code,
      message,
      handled: true,
      formatted: {
        title: i18next.t(`errors:${code}.title`),
        description: i18next.t(`errors:${code}.description`),
      },
    };
  } else {
    return {
      code,
      message,
      handled: false,
      formatted: {
        title: i18next.t("errors:unhandled.title"),
        description: i18next.t("errors:unhandled.description", { email: contact.helpEmail[i18next.language as Language] }),
      },
    };
  }
};

/*

  Error handler:
  - Handles different types of errors
  - Returns a formatted error object with a title and description, if available

  How to use:
  - In any try catch block, call handleError(error) to get the formatted error object
  - you can display it using a toast or a state variable, whatever, but it will always be formatted

*/

export const handleError = (error: unknown): HandledError => {
  // Clicknpark SDK Error
  if (error instanceof CPException) {
    logger.error("CPException", error);
    return formatError(error.code.toString(), error.message);
  }

  // App Specific Error
  else if (error instanceof AppError) {
    logger.error("AppError", error);
    return formatError(error.code, error.message);
  }

  // JavaScript Error (make sure to check that last since CPException and AppError are also instances of Error)
  else if (error instanceof Error) {
    logger.error("Error", error);
    return formatError("UNHANDLED_ERROR", error.message);
  }

  // Unhandled Error
  else {
    // Must have at least 'name', 'message', and 'stack' properties
    if (isErrorLike(error)) {
      logger.error("Unhandled Error (error like)", error);
      return formatError(error?.code || "UNHANDLED_ERROR", error?.message);
    } else {
      // Converts to a plain object
      const serializedError = serializeError(error);

      // Converts to a string
      const stringifiedError = JSON.stringify(serializedError);

      logger.error("Unhandled Error", stringifiedError);
      return formatError("UNHANDLED_ERROR", stringifiedError);
    }
  }
};
