import Header from "@/components/layout/header";
import Wrapper from "@/components/layout/wrapper";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
// import Heading from "@/components/ui/Heading";
import { Separator } from "@/components/ui/separator";
import Spinner from "@/components/ui/spinner";
import useDetectOs from "@/lib/hooks/use-detect-os";
import { logger } from "@/lib/services/debug/logger.services";
import UtmUtils from "@/lib/utils/utm.utils";
import branch from "branch-sdk";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useSearchParams } from "react-router-dom";

export default function PromoCode() {
  const { t } = useTranslation("promoCode");
  const { isAndroidOrIOS } = useDetectOs();
  const [params] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [deferredDeepLink, setDeferredDeepLink] = useState<string>("#");

  const code = params.get("code") as string | null;
  const pid = params.get("pid") as string | null;
  const vid = params.get("vid") as string | null;

  useEffect(() => {
    setLoading(true);

    if (!code) {
      window.location.href = "https://clicknpark.com";
      return;
    }

    branch.link(
      {
        channel: "web-app",
        feature: "redeem-promo-code",
        campaign: UtmUtils.getSavedUtmParams().campaign,
        data: {
          action: {
            type: "redeem-promo-code",
            payload: {
              code,
              pid: pid || undefined,
              vid: vid || undefined,
            },
          },
          utmParams: UtmUtils.getSavedUtmParams(),
          posthogDistinctId: posthog.get_distinct_id(),
        },
      },
      (err, res) => {
        if (res) setDeferredDeepLink(res);
        logger.error(err, res);
      }
    );

    setLoading(false);
  }, []);

  return (
    <>
      <Header />

      <Wrapper widthClass="h-screen flex items-center justify-center w-[600px] max-w-full">
        <Card paddingSize="lg" className="md:p-0 p-0 m-5 rounded-xl">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="p-6 px-4 pb-0 flex flex-wrap items-center justify-between">
                <img className="md:w-[180px] w-[100px] md:mr-10 mr-5" src="/images/app.png" alt="app" />

                <div className="flex-1">
                  {/* <Heading level="h4">
                    {t("downloadTheAppTitle", { code })}
                  </Heading> */}
                  <p className="leading-relaxed font-bold text-sm text-rapide-600 mt-2">{t("downloadTheAppDescription")}</p>
                  {!isAndroidOrIOS ? (
                    <div className="flex items-center">
                      <QRCode size={120} value={deferredDeepLink} />
                      <p className="ml-5 text-sm font-bold">{t("scanToDownload")}</p>
                    </div>
                  ) : null}
                </div>
              </div>

              {isAndroidOrIOS ? (
                <>
                  <Separator />
                  <div className="px-4">
                    <Button className="mt-3 mb-3 w-full" size="lg">
                      <a href={deferredDeepLink}>{t("downloadApp")}</a>
                    </Button>
                  </div>
                </>
              ) : null}
            </>
          )}
        </Card>
      </Wrapper>
    </>
  );
}
