import Loader from "@/components/layout/loader";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle } from "@/components/ui/drawer";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import Spinner from "@/components/ui/spinner";
import { useMediaQuery } from "@/lib/hooks/use-media-query";
import { useToast } from "@/lib/hooks/use-toast";
import { HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { GET_RESERVATION_QUERY_KEY, useAttachVehicleToReservationMutation } from "@/lib/services/reservations.services";
import { useAddVehicleMutation, useGetVehiclesQuery } from "@/lib/services/vehicles.services";
import { StringFormattingUtils } from "@/lib/utils/formatting.utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogClose } from "@radix-ui/react-dialog";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";
import { z } from "zod";

interface Props {
  reservationId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onSubmitted?: () => void;
  onError?: (error: HandledError) => void;
}

const VehiclePlateFormHeader = () => {
  const { t } = useTranslation(["forms", "formValidation"]);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return isDesktop ? (
    <DialogHeader>
      <DialogTitle>{t("forms:vehicle.changeVehicle")}</DialogTitle>
    </DialogHeader>
  ) : (
    <DrawerHeader className="text-left">
      <DrawerTitle>{t("forms:vehicle.changeVehicle")}</DrawerTitle>
    </DrawerHeader>
  );
};

const VehiclePlateFormFooter = (props: Props) => {
  const { t } = useTranslation(["common"]);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return isDesktop ? (
    <DialogFooter>
      <DialogClose asChild>
        <Button variant="outline" color="secondary" onClick={props.onClose}>
          {t("common:ui.cancel")}
        </Button>
      </DialogClose>
      <Button type="submit">{t("common:ui.change")}</Button>
    </DialogFooter>
  ) : (
    <DrawerFooter>
      <Button type="submit">{t("common:ui.change")}</Button>
      <DrawerClose asChild>
        <Button variant="outline" color="secondary" onClick={props.onClose}>
          {t("common:ui.cancel")}
        </Button>
      </DrawerClose>
    </DrawerFooter>
  );
};

const VehiclePlateForm = (props: Props) => {
  const { t } = useTranslation(["forms", "validation"]);
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const userVehiclesQuery = useGetVehiclesQuery({ enabled: true });
  const addVehicleMutation = useAddVehicleMutation();
  const attachVehicleToReservationMutation = useAttachVehicleToReservationMutation();

  const vehicles = useMemo(() => {
    return userVehiclesQuery.data?.map((vehicle) => {
      if (vehicle?.model) {
        return {
          id: vehicle.id,
          name: getVehicleName(vehicle),
          model: vehicle.model,
          make: vehicle.make,
          plate: vehicle.plate,
        };
      } else {
        return {
          id: vehicle.id,
          name: getVehicleName(vehicle),
          noModelString: vehicle?.noModelString || "",
          plate: vehicle.plate,
        };
      }
    });
  }, [userVehiclesQuery.data]);

  const formSchema = z.object({
    vehicle: z.string().min(1, t("validation:vehicleRequired")),
    plate: z.string().min(1, t("validation:licencePlateRequired")),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      vehicle: "",
      plate: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    let vid: string | undefined;

    try {
      if (values.vehicle === "new" || values.vehicle === "first") {
        const data = await addVehicleMutation.mutateAsync({
          plate: values.plate,
          noModelString: "_UNKNOWN_",
        });

        vid = data.object.objectId;
      } else {
        vid = values.vehicle;
      }

      await attachVehicleToReservationMutation.mutateAsync({
        reservationId: props.reservationId,
        vehicleId: vid,
      });

      await queryClient.invalidateQueries({ queryKey: [GET_RESERVATION_QUERY_KEY, props.reservationId] });

      props.onSuccess();
    } catch (error) {
      const handledError = handleError(error);
      if (handledError.handled) toast({ ...handledError.formatted, variant: "destructive" });
      else toast({ title: handledError.code, description: handledError.message, variant: "destructive" });
    }
  }

  const vehicle = form.watch("vehicle");

  useEffect(() => {
    if (vehicle === "new") form.setValue("plate", "");
    else {
      const selectedVehicle = vehicles?.find((v) => v.id === vehicle);
      if (selectedVehicle) form.setValue("plate", selectedVehicle.plate);
    }
  }, [vehicle]);

  if (userVehiclesQuery.isFetching) {
    return (
      <div className="relative z-0 flex h-[100px] items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const PlateField = (
    <FormField
      name="plate"
      control={form.control}
      render={({ field }) => (
        <FormItem>
          <Label>{t("forms:vehicle.plateNumber")}</Label>
          <FormControl>
            <Input {...field} value={StringFormattingUtils.toNoSpaces(field.value)} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return (
    <>
      <VehiclePlateFormHeader />

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-3">
            {vehicles && vehicles.length > 0 ? (
              <div className="space-y-3">
                <FormField
                  name="vehicle"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormControl>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <SelectTrigger>
                            <SelectValue placeholder={t("forms:vehicle.selectVehicle")} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="new">{t("forms:vehicle.newVehicle")}</SelectItem>
                            {vehicles.map((vehicle) => (
                              <SelectItem key={vehicle.id} value={vehicle.id}>
                                {vehicle.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {vehicle === "new" ? PlateField : null}
              </div>
            ) : (
              <>
                <FormField
                  name="vehicle"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="hidden">
                      <FormControl>
                        <Input {...field} type="hidden" value={field.value} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                {PlateField}
              </>
            )}

            <Alert variant="error">
              <div className="flex items-center space-x-4">
                <MaterialSymbol icon="warning" size={24} fill />
                <AlertDescription>{t("forms:vehicle.plateWarning")}</AlertDescription>
              </div>
            </Alert>
          </div>

          {form.formState.isSubmitting ? <Loader title={t("forms:vehicle.changingVehicle")} /> : null}

          <VehiclePlateFormFooter {...props} />
        </form>
      </Form>

      {}
    </>
  );
};

export function VehiclePlateDialog(props: Props) {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return isDesktop ? (
    <Dialog open={props.isOpen} onOpenChange={props.onClose}>
      <DialogContent>
        <VehiclePlateForm {...props} />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={props.isOpen} onClose={props.onClose}>
      <DrawerContent>
        <VehiclePlateForm {...props} />
      </DrawerContent>
    </Drawer>
  );
}

// This function generates the name for a vehicle
function getVehicleName(vehicle: any): string {
  if (vehicle?.model) {
    return `${vehicle.make} ${vehicle.model} [${vehicle.plate}]`;
  } else if (vehicle?.noModelString) {
    if (vehicle?.noModelString === "_UNKNOWN_") {
      return vehicle.plate || "";
    } else {
      return `${vehicle?.noModelString || ""} [${vehicle?.plate || ""}]`;
    }
  } else {
    return vehicle.plate;
  }
}
