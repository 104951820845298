import { Label } from "@/components/ui/label";
import { DateUtils } from "@/lib/utils/date.utils";
import { Dayjs } from "dayjs";
import { MaterialSymbol } from "react-material-symbols";

interface Props {
  date: Dayjs;
  label: string;
}

export default function TimePickerSelect({ date, label }: Props) {
  return (
    <span className="flex-1">
      <span className="text-sm">{label}</span>
      <Label className="flex items-center justify-between font-bold text-[13px]">
        <span className="truncate">{DateUtils.formatDate(date)}</span>
        <MaterialSymbol icon="expand_more" className="text-rapide-600" size={20} />
      </Label>
    </span>
  );
}
