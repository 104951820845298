import PaymentIcons from "@/components/containers/search/sidebar/booking/payment-icons";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import Heading from "@/components/ui/heading";
import Spinner from "@/components/ui/spinner";
import useDetectOs from "@/lib/hooks/use-detect-os";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { logger } from "@/lib/services/debug/logger.services";
import { AppError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { useGetParkAvailabilityQuery, useGetParkPriceQuery, useGetParkQuery } from "@/lib/services/parks.services";
import { RoadblockReason, TrackingServices } from "@/lib/services/tracking.services";
import SelectParkTimeContext from "@/lib/store/contexts/select-time.context";
import { NumberFormattingUtils } from "@/lib/utils/formatting.utils";
import UtmUtils from "@/lib/utils/utm.utils";
import branch from "branch-sdk";
import { Download } from "lucide-react";
import posthog from "posthog-js";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";
import QRCode from "react-qr-code";

interface ParkBookingProps {
  selectedSlotPrice?: string;
}

export default function ParkBooking({ selectedSlotPrice }: ParkBookingProps) {
  const { t } = useTranslation(["park"]);
  const { language } = useLanguageUtils();
  const { isAndroidOrIOS } = useDetectOs();

  const context = useContext(SelectParkTimeContext);
  const parkId = context?.parkId;
  const start = context.start;
  const end = context.end;

  const parkQuery = useGetParkQuery({ parkId });
  const park = parkQuery.data;

  const getParkAvailabilityQuery = useGetParkAvailabilityQuery({ parkId, request: { startDate: start, endDate: end } });
  const availability = getParkAvailabilityQuery.data;

  const getParkPriceQuery = useGetParkPriceQuery({ parkId, request: { startDate: start, endDate: end } });
  const price = getParkPriceQuery.data;

  const [loading, setLoading] = useState<boolean>(true);
  const [deferredDeepLink, setDeferredDeepLink] = useState<string>("#");
  const [appDownloadModalOpen, setAppDownloadModalOpen] = useState<boolean>(false);

  const appDownloadRequired = park?.accessType !== "free" && park?.accessType !== "digitalTicket";
  const isLoading = parkQuery.isLoading || getParkAvailabilityQuery.isLoading || getParkPriceQuery.isLoading;
  const error = parkQuery.error || getParkAvailabilityQuery.error || getParkPriceQuery.error;

  useEffect(() => {
    if (!availability) return;
    if (!availability?.isAvailable) {
      TrackingServices.trackRoadblock(["unavailable"]);
    }
  }, [availability]);

  useEffect(() => {
    if (!park) return;
    if (park.accessType !== "free" && park.accessType !== "digitalTicket") {
      let reasons: RoadblockReason[] = ["app-required"];

      if (park.accessType === "accessDevice") {
        reasons.push("access-type-sesame");
      } else if (park.accessType === "valet") {
        reasons.push("access-type-valet");
      }

      TrackingServices.trackRoadblock(reasons);
    }
  }, [park]);

  useEffect(() => {
    if (!appDownloadRequired) return;

    setLoading(true);

    branch.link(
      {
        channel: "web-app",
        feature: "open-park",
        campaign: UtmUtils.getSavedUtmParams().campaign,
        data: {
          action: {
            type: "open-park",
            payload: {
              id: park?.id,
              start,
              end,
            },
          },
          utmParams: UtmUtils.getSavedUtmParams(),
          posthogDistinctId: posthog.get_distinct_id(),
        },
      },
      (err, res) => {
        if (res) setDeferredDeepLink(res);
        logger.error(err, res);
      }
    );

    setLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className="text-center items-start pt-0">
        <Button size="lg" className="w-full" disabled>
          {t("pleaseWait")}
        </Button>

        <PaymentIcons />
      </div>
    );
  }

  /* Handle unavailable parks */
  if (getParkAvailabilityQuery.isSuccess && !availability?.isAvailable) {
    const unavailableError = handleError(new AppError("Parking spot is not available", "unavailableSpot"));
    return (
      <Alert variant="softError">
        <AlertTitle>{unavailableError.formatted.title}</AlertTitle>
        <AlertDescription>{unavailableError.formatted.description}</AlertDescription>
      </Alert>
    );
  }

  /* Handle errors or missing data */
  if (!isLoading && error) {
    const handledError = handleError(error);
    return (
      <Alert variant="error">
        <AlertTitle>{handledError.formatted.title}</AlertTitle>
        <AlertDescription>{handledError.formatted.description}</AlertDescription>
      </Alert>
    );
  }

  const AppDownloadButton = () => (
    <Button
      size="lg"
      className="w-full"
      disabled={getParkPriceQuery.isFetching || getParkAvailabilityQuery.isFetching}
      onClick={() => {
        if (!isAndroidOrIOS) setAppDownloadModalOpen(true);
        else if (selectedSlotPrice && park)
          TrackingServices.trackClickedDownloadApp(parseFloat(selectedSlotPrice), park?.currency || "CAD", park, isAndroidOrIOS);
        else if (park && price) TrackingServices.trackClickedDownloadApp(price.total, price.currency, park, isAndroidOrIOS);
      }}
    >
      <Download className="mr-2 w-5 h-5" />
      {getParkPriceQuery.isFetching || getParkAvailabilityQuery.isFetching ? t("pleaseWait") : t("downloadAndBook")}
    </Button>
  );

  return (
    <div className="text-center items-start pt-0">
      {appDownloadRequired ? (
        <Alert variant="info" className="mb-2.5 text-left" size="sm">
          <AlertTitle>{t("downloadRequired")}</AlertTitle>
          <AlertDescription> {t("downloadRequiredDescription")}</AlertDescription>
        </Alert>
      ) : null}

      {appDownloadRequired ? (
        isAndroidOrIOS ? (
          <a className="block cursor-pointer" href={deferredDeepLink}>
            <AppDownloadButton />
          </a>
        ) : (
          <AppDownloadButton />
        )
      ) : (
        <a
          className="block cursor-pointer"
          href={UtmUtils.appendUtmStringToUrl(
            `${import.meta.env.VITE_CLICKNPARK_CHECKOUT_URL}/?m=st&pid=${parkId}&start=${start}&end=${end}&lang=${language}`
          )}
        >
          <Button size="lg" className="w-full" disabled={getParkPriceQuery.isFetching || getParkAvailabilityQuery.isFetching}>
            <MaterialSymbol icon="lock" className="mr-2" size={20} />
            {getParkPriceQuery.isFetching || getParkAvailabilityQuery.isFetching
              ? t("pleaseWait")
              : t("bookNow", {
                  price:
                    selectedSlotPrice ||
                    (price?.total && price?.currency ? NumberFormattingUtils.formatAmountWithoutCurrency(price.total, language, price.currency) : ""),
                })}
          </Button>
        </a>
      )}

      <PaymentIcons />

      <Dialog open={appDownloadModalOpen} onOpenChange={() => setAppDownloadModalOpen(!appDownloadModalOpen)}>
        <DialogContent className="pb-0 border-0">
          <Heading className="pr-5">{t("scanQRToDownload")}</Heading>
          <div className="flex justify-between items-end">
            <div className="w-2/3 flex-1 space-y-5 mb-5 text-base">
              <p>{t("downloadText1")}</p>
              <p>{t("downloadText2")}</p>
              {loading ? (
                <div className="w-[120px] h-[120px] border border-silver-300 flex items-center justify-center rounded-md">
                  <Spinner />
                </div>
              ) : (
                <QRCode size={120} value={deferredDeepLink} />
              )}
            </div>

            <img src="/images/app.png" className="h-[280px] ml-5" alt="" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
