import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useDetectOs from "@/lib/hooks/use-detect-os";
import dayjs, { Dayjs } from "dayjs";
import React, { RefObject, useEffect } from "react";

interface FieldTimepickerProps {
  date: Dayjs;
  onSelect: (date: Dayjs) => void;
  minDate: Dayjs;
  minTimeApplies?: boolean; // For start date, min time only applies if the date is today
  locale?: string;
}

function getTimeParts(time: string) {
  const h = time.split(":")[0];
  const m = time.split(":")[1];
  return { h, m };
}

function formatTimeForLocale(h: string, m: string, locale: string) {
  let timeLabel = `${h}:${m}`;

  if (locale === "en") {
    const hour = Number(h);
    const minute = Number(m);
    const isPM = hour >= 12;
    const hour12 = hour % 12 || 12;
    timeLabel = `${hour12}:${minute.toString().padStart(2, "0")} ${isPM ? "PM" : "AM"}`;
  }

  return timeLabel;
}

function isTimeDisabled(h: string, m: string, minTimeApplies: boolean, minDate: any, date: any) {
  const disabled = minTimeApplies
    ? date.hour(Number(h)).minute(Number(m)).second(0).millisecond(0).isBefore(minDate.second(0).millisecond(0))
    : false;

  return disabled;
}

export default function FieldTimepicker({ date, onSelect, minDate, minTimeApplies = true, locale = "en" }: FieldTimepickerProps) {
  const { isAndroidOrIOS } = useDetectOs();

  // Slices of 15 minutes in a day
  const times = Array.from({ length: 96 }, (_, i) =>
    date
      .startOf("day")
      .add(i * 15, "minute")
      .format("HH:mm")
  );

  const refs = times.reduce((acc, time) => {
    acc[time] = React.createRef();
    return acc;
  }, {} as Record<string, RefObject<HTMLDivElement>>);

  useEffect(() => {
    if (date && refs?.[date.format("HH:mm")]?.current) {
      refs[date.format("HH:mm")].current!.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [date]);

  if (isAndroidOrIOS) {
    return (
      <select className="p-4 border-none bg-white rounded-md" onChange={(e) => onSelect(dayjs(`${date.format("YYYY-MM-DD")} ${e.target.value}`))}>
        {times.map((time, index) => {
          const { h, m } = getTimeParts(time);
          let timeLabel = formatTimeForLocale(h, m, locale);
          const disabled = isTimeDisabled(h, m, minTimeApplies, minDate, date);

          return (
            <option key={index} value={time} selected={date.format("HH:mm") === time} disabled={disabled}>
              {timeLabel}
            </option>
          );
        })}
      </select>
    );
  }

  return (
    <Select value={date.format("HH:mm")} onValueChange={(value) => onSelect(dayjs(`${date.format("YYYY-MM-DD")} ${value}`))}>
      <SelectTrigger className="bg-white border-none">
        <SelectValue placeholder={date.format("HH:mm")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {times.map((time, index) => {
            const { h, m } = getTimeParts(time);
            let timeLabel = formatTimeForLocale(h, m, locale);
            const disabled = isTimeDisabled(h, m, minTimeApplies, minDate, date);

            return (
              <SelectItem key={index} value={time} disabled={disabled}>
                {timeLabel}
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
