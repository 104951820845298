import HeaderVenue from "@/components/containers/search/sidebar/header/header-venue";
import Park from "@/components/containers/search/sidebar/lists/tile/park-tile";
import Loader from "@/components/layout/loader";
import { getRoute } from "@/lib/configs/routes.config";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { useGetParkGroupQuery } from "@/lib/services/park-groups.services";
import { useMapStore } from "@/lib/store/map.store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function SelectParkForParkGroup() {
  const navigate = useNavigate();
  const { t } = useTranslation(["search"]);
  const { language } = useLanguageUtils();

  const params = useParams<{ parkGroupId: string }>();
  const parkGroupId = params.parkGroupId;

  const getParkGroupQuery = useGetParkGroupQuery({
    parkGroupId,
    locale: language,
  });

  const parkGroup = getParkGroupQuery.data;
  const parks = parkGroup?.parks || [];

  const { setMarkers, recenter, setHoveredMarkerIndex } = useMapStore();

  useEffect(() => {
    if (!parkGroup || !parks) return;

    setMarkers([
      {
        type: "destination",
        label: parkGroup.name,
        id: parkGroup.id,
        coordinates: {
          lat: parkGroup.location.latitude,
          lng: parkGroup.location.longitude,
        },
      },
      ...parks.map((park, index) => ({
        label: `P${++index}`,
        id: park.objectId,
        coordinates: {
          lat: park.location.lat,
          lng: park.location.lng,
        },
      })),
    ]);

    recenter();
  }, [parkGroup, parks]);

  function selectPark(parkId: string) {
    navigate(
      getRoute("SEARCH_SELECT_TIME", {
        parkId,
      })
    );
  }

  return (
    <div className="relative">
      <HeaderVenue
        {...{
          loading: getParkGroupQuery.isLoading,
          title: parkGroup?.name,
        }}
      />

      {getParkGroupQuery.isLoading ? (
        <section className="relative p-20">
          <Loader />
        </section>
      ) : (
        <section className="p-5 overflow-auto flex-1">
          <strong>{t("search:parkingNearLocation", { location: parkGroup?.name })}</strong>
          <ul>
            {parks?.map((park, index) => (
              <Park
                key={park.objectId + index}
                {...{
                  park,
                  parkGroup,
                  label: `P${++index}`,
                  displayAvailability: false,
                  onClick: () => selectPark(park.id),
                  onMouseEnter: () => setHoveredMarkerIndex(index),
                  onMouseLeave: () => setHoveredMarkerIndex(),
                }}
              />
            ))}
          </ul>
        </section>
      )}
    </div>
  );
}
