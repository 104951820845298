import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle } from "@/components/ui/drawer";
import { useMediaQuery } from "@/lib/hooks/use-media-query";
import { HandledError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { DialogClose } from "@radix-ui/react-dialog";
import { useTranslation } from "react-i18next";

interface Props {
  reservationId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onSubmitted?: () => void;
  onError?: (error: HandledError) => void;
}

const CancelReservationFormHeader = () => {
  const { t } = useTranslation(["forms", "formValidation"]);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return isDesktop ? (
    <DialogHeader>
      <DialogTitle>{t("forms:cancelReservation.cancelReservation")}</DialogTitle>
    </DialogHeader>
  ) : (
    <DrawerHeader className="text-left">
      <DrawerTitle>{t("forms:cancelReservation.cancelReservation")}</DrawerTitle>
    </DrawerHeader>
  );
};

const CancelReservationFormFooter = (props: Props) => {
  const { t } = useTranslation(["common"]);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return isDesktop ? (
    <DialogFooter>
      <DialogClose asChild>
        <Button variant="outline" color="secondary" onClick={props.onClose}>
          {t("common:ui.cancel")}
        </Button>
      </DialogClose>
      <Button type="submit">{t("common:ui.change")}</Button>
    </DialogFooter>
  ) : (
    <DrawerFooter>
      <Button type="submit">{t("common:ui.change")}</Button>
      <DrawerClose asChild>
        <Button variant="outline" color="secondary" onClick={props.onClose}>
          {t("common:ui.cancel")}
        </Button>
      </DrawerClose>
    </DrawerFooter>
  );
};

const CancelReservationForm = (props: Props) => {
  // const getCancellationOptionsQuery = useGetCancellationOptionsQuery(props.reservationId);
  // const options = getCancellationOptionsQuery.data;

  return (
    <>
      <CancelReservationFormHeader />

      {/* {form.formState.isSubmitting ? <Loader title={t("forms:vehicle.changingVehicle")} /> : null} */}

      <CancelReservationFormFooter {...props} />
    </>
  );
};

export function CancelReservationDialog(props: Props) {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return isDesktop ? (
    <Dialog open={props.isOpen} onOpenChange={props.onClose}>
      <DialogContent>
        <CancelReservationForm {...props} />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={props.isOpen} onClose={props.onClose}>
      <DrawerContent>
        <CancelReservationForm {...props} />
      </DrawerContent>
    </Drawer>
  );
}
