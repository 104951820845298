import AccountShell from "@/components/layout/shell/account/shell";
import { Outlet } from "react-router-dom";

export default function Account() {
  return (
    <AccountShell>
      <Outlet />
    </AccountShell>
  );
}
