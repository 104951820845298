import cn from "@/lib/utils/cn.utils";
import * as React from "react";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  padding?: ("top" | "bottom" | "left" | "right")[];
  paddingSize?: "sm" | "md" | "lg" | "none";
  backgroundStyle?: "none" | "default";
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, padding = ["top", "bottom", "left", "right"], paddingSize = "md", backgroundStyle = "default", ...props }, ref) => {
    let paddingClasses = "";

    if (padding.includes("top")) {
      if (paddingSize === "sm") paddingClasses += "pt-4";
      if (paddingSize === "md") paddingClasses += "pt-6";
      if (paddingSize === "lg") paddingClasses += "pt-8";
    }

    if (padding.includes("bottom")) {
      if (paddingSize === "sm") paddingClasses += " pb-4";
      if (paddingSize === "md") paddingClasses += " pb-6";
      if (paddingSize === "lg") paddingClasses += " pb-8";
    }

    if (padding.includes("left")) {
      if (paddingSize === "sm") paddingClasses += " pl-4";
      if (paddingSize === "md") paddingClasses += " pl-6";
      if (paddingSize === "lg") paddingClasses += " pl-8";
    }

    if (padding.includes("right")) {
      if (paddingSize === "sm") paddingClasses += " pr-4";
      if (paddingSize === "md") paddingClasses += " pr-6";
      if (paddingSize === "lg") paddingClasses += " pr-8";
    }

    return (
      <div
        ref={ref}
        className={cn(
          paddingClasses,
          backgroundStyle === "default" ? "rounded-xl border border-silver-800 bg-white shadow-lg shadow-silver-700" : "",
          className
        )}
        {...props}
      />
    );
  }
);

Card.displayName = "Card";

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex flex-col pb-6", className)} {...props} />
));

CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(({ className, ...props }, ref) => (
  <h3 ref={ref} className={cn("font-medium leading-none tracking-tight text-lg", className)} {...props} />
));

CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({ className, ...props }, ref) => (
  <p ref={ref} className={cn("text-sm text-slate-500 dark:text-slate-400 mt-2", className)} {...props} />
));

CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn(className)} {...props} />
));

CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex items-center", className)} {...props} />
));

CardFooter.displayName = "CardFooter";

export { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle };
