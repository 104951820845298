import ParkBooking from "@/components/containers/search/sidebar/booking/booking";
import DurationCustom from "@/components/containers/search/sidebar/booking/duration-custom";
import Loader from "@/components/layout/loader";
import { Button } from "@/components/ui/button";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { useGetParkSlotPricesQuery } from "@/lib/services/parks.services";
import { TrackingServices } from "@/lib/services/tracking.services";
import { DEFAULT_SELECTED_SLOT_INDEX, SLOTS } from "@/lib/store/configs/defaut-search-params";
import SelectParkTimeContext from "@/lib/store/contexts/select-time.context";
import cn from "@/lib/utils/cn.utils";
import { DateUtils } from "@/lib/utils/date.utils";
import dayjs from "dayjs";
import { ArrowRight, ChevronLeft, ClockIcon } from "lucide-react";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export type Slot = {
  durationInHours: number;
  formattedPrice: string;
  currency?: string;
};

interface SlotProps extends Slot {
  selected: boolean;
  onClick: () => void;
}

const Slot = ({ durationInHours, formattedPrice, selected, onClick }: SlotProps) => {
  const { t } = useTranslation(["search"]);

  return (
    <li
      onClick={onClick}
      className={cn(
        "cursor-pointer text-center relative z-20 border-2 transition-all  flex flex-col items-center justify-center",
        selected
          ? "bg-white border-simple-900 shadow-lg ring-[3px] ring-attention-600 text-simple-900"
          : "border-rapide-300 hover:shadow-lg text-simple-900",
        "p-4 rounded-lg"
      )}
    >
      <strong className={cn(`cursor-pointer block font-semibold text-sm`)}>
        {durationInHours} {t(durationInHours > 1 ? "hours" : "hour")}
      </strong>

      <span className={cn("cursor-pointer text-md font-bold", selected ? "text-attention-700" : "")}>{formattedPrice}</span>
    </li>
  );
};

interface SummaryProps {
  end: string;
}

const Summary = ({ end }: SummaryProps) => {
  const { t } = useTranslation(["search"]);
  const { language } = useLanguageUtils();

  return (
    <Fragment>
      <div className="flex justify-center items-stretch space-x-4 mt-5 text-simple-900 mb-2">
        <div className="flex justify-between items-center gap-2">
          <strong className="block md:text-lg text-base">{t("search:now")}</strong>
          <ArrowRight className="w-5 h-5" />
          <strong className="block md:text-lg text-base">{DateUtils.formatDate(dayjs(end), { locale: language })}</strong>
        </div>
      </div>
    </Fragment>
  );
};

export default function Duration() {
  const { t } = useTranslation(["search"]);
  const [searchParams, setSearchParams] = useSearchParams();

  const context = useContext(SelectParkTimeContext);
  const parkId = context?.parkId;
  const start = context.start;

  // Unless a start date is provided in the search params, we default the picker mode to slots
  const mode = searchParams.get("mode") as string | undefined;

  const [selectedSlot, setSelectedSlot] = useState<number>(DEFAULT_SELECTED_SLOT_INDEX);
  const [pickerMode, setPickerMode] = useState<"slots" | "custom">(mode === "custom" ? "custom" : "slots");

  const slotPricesQuery = useGetParkSlotPricesQuery({
    parkId,
    requests: SLOTS.map((slot) => ({
      slot,
      request: {
        startDate: start,
        endDate: dayjs(start).add(slot, "hour").toISOString(),
      },
    })),
  });

  const slots = slotPricesQuery.data;

  if (pickerMode === "slots") {
    if (slotPricesQuery.isLoading) {
      return (
        <div className="relative py-10">
          <Loader />
        </div>
      );
    }

    return (
      <Fragment>
        <ul className="cursor-pointer grid md:grid-cols-3 grid-cols-3 gap-2">
          {slots?.map((slot, index) => (
            <Slot
              key={index}
              currency={slot.currency}
              durationInHours={slot.durationInHours}
              formattedPrice={slot.formattedPrice}
              selected={index === selectedSlot}
              onClick={() => {
                setSelectedSlot(index);
                setSearchParams(
                  {
                    ...Object.fromEntries(searchParams.entries()),
                    start: dayjs(start).isSameOrBefore(dayjs(), "day") ? dayjs().toISOString() : dayjs(start).toISOString(),
                    end: dayjs(start).add(slot.durationInHours, "hour").toISOString(),
                  },
                  { replace: true }
                );
                TrackingServices.trackDurationSlotClick(slots[index].durationInHours.toString(), slots[index].price, slots[index].currency || "CAD");
              }}
            />
          ))}
          <li
            className="cursor-pointer text-center relative z-20 border-2 transition-all flex flex-col items-center justify-center p-4 rounded-lg border-rapide-300 hover:shadow-lg  text-simple-900"
            onClick={() => {
              setPickerMode("custom");
              TrackingServices.trackDurationModeSwitch("slots", "custom");
            }}
          >
            <ClockIcon className="w-4 h-4 mx-auto mb-1  text-simple-900" />
            <div className={`cursor-pointer text-sm font-bold  text-simple-900 leading-4`}>{t("customDuration")}</div>
          </li>
        </ul>

        {slots ? (
          <Summary
            {...{
              end: dayjs(start).add(slots[selectedSlot].durationInHours, "hour").toISOString(),
            }}
          />
        ) : null}

        <ParkBooking selectedSlotPrice={slots?.[selectedSlot].formattedPrice} />
      </Fragment>
    );
  } else {
    return (
      <>
        <Button
          onClick={() => {
            setPickerMode("slots");
            const params = Object.fromEntries(searchParams.entries());
            delete params.start;
            delete params.end;
            setSearchParams(params, { replace: true });
          }}
          variant="link"
          size="sm"
          className="mb-2"
        >
          <ChevronLeft className="w-3 h-3 inline-block mr-0.5" />
          {t("search:backToSlots")}
        </Button>
        <DurationCustom />
        <ParkBooking />
      </>
    );
  }
}
