export type LogFn = {
  (message?: any, ...optionalParams: any[]): void;
};

export type Logger = {
  log: LogFn;
  warn: LogFn;
  error: LogFn;
  info: LogFn;
};

export type LogLevel = "log" | "warn" | "error" | "info";

// The NO_OP function is a "no operation" function. It's a function that does nothing.
// It's used as a placeholder for the log and warn methods when the log level is set to 'error'.
// This means that when the log level is 'error', calling log or warn will have no effect.
const NO_OP: LogFn = () => {};

export function createLogger(options?: { level?: LogLevel }): Logger {
  const { level } = options || {};

  const error = console.error.bind(console);
  let warn: LogFn;
  let log: LogFn;
  let info: LogFn;

  if (level === "error") {
    warn = NO_OP;
    log = NO_OP;
    info = NO_OP;
  } else {
    warn = console.warn.bind(console);

    if (level === "warn") {
      log = NO_OP;
      info = NO_OP;
    } else {
      log = console.log.bind(console);
      info = console.info.bind(console);
    }
  }

  return { log, warn, error, info };
}

const LOG_LEVEL: LogLevel = import.meta.env.PROD ? "warn" : "log";

export const logger = createLogger({ level: LOG_LEVEL });
