import account from "./account.json";
import auth from "./auth.json";
import common from "./common.json";
import dates from "./dates.json";
import errors from "./errors.json";
import forms from "./forms.json";
import park from "./park.json";
import promoCode from "./promo-code.json";
import reservations from "./reservations.json";
import search from "./search.json";

const locale = {
  account,
  auth,
  common,
  dates,
  park,
  search,
  errors,
  promoCode,
  reservations,
  forms,
};

const enLocale = locale; // Simplifies copy paste... every locale index is the same

export default enLocale;
