import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enLocale from "./en/_";
import frLocale from "./fr/_";

export const DEFAULT_NS = "common";
export const SUPPORTED_LANGUAGES: string[] = ["en", "fr"];
export const DEFAULT_LANGUAGE: string = "en";

export const RESOURCES = {
  fr: frLocale,
  en: enLocale,
} as const;

const urlParams = new URLSearchParams(window.location.search);
const queryLanguage = urlParams.get("lang");
const storedLanguage = StorageUtils.getItem(keys.LANGUAGE);
const initialLanguage = queryLanguage ?? storedLanguage ?? DEFAULT_LANGUAGE;

if (queryLanguage) {
  StorageUtils.setItem(keys.LANGUAGE, queryLanguage);
}

i18next.use(initReactI18next).init(
  {
    load: "all",
    lng: initialLanguage,
    defaultNS: DEFAULT_NS,
    fallbackLng: [DEFAULT_LANGUAGE],
    supportedLngs: SUPPORTED_LANGUAGES,
    returnObjects: true,
    resources: RESOURCES,
    debug: false,
    keySeparator: ".",
  },
  () => {}
);

export default i18next;
