import mapStyles from "@/assets/map-style.json";
import { Button } from "@/components/ui/button";
import cn from "@/lib/utils/cn.utils";
import { GoogleMap, OverlayView, OverlayViewF, useJsApiLoader } from "@react-google-maps/api";
import { ExternalLink } from "lucide-react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export interface MapCoordinates {
  lat: number;
  lng: number;
}

export type CPMarker = {
  id: string;
  coordinates: { lat: number; lng: number };
  label?: string;
  type?: "parking" | "destination";
};

interface MapProps {
  className?: string;
  center: MapCoordinates;
  markers: CPMarker[];
  zoom?: number;
  disableGestureHandling?: boolean;
  streetViewLink?: string;
  googleMapsLink?: string;
  shouldRecenter?: boolean;
  hoveredMarkerIndex?: number;
}

export default function Map({
  className,
  center,
  markers,
  zoom = 15,
  shouldRecenter = false,
  disableGestureHandling,
  streetViewLink,
  googleMapsLink,
  hoveredMarkerIndex,
}: MapProps) {
  const { t } = useTranslation(["common"]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  });

  const mapRef = useRef<google.maps.Map>();

  useEffect(() => {
    if (!shouldRecenter) return;

    if (mapRef.current) {
      const bounds = new google.maps.LatLngBounds();

      if (markers.length > 1) {
        markers.forEach((marker) => bounds.extend(new google.maps.LatLng(marker.coordinates.lat, marker.coordinates.lng)));
        mapRef.current.fitBounds(bounds, 100);
        mapRef.current.setCenter(bounds.getCenter());
      } else {
        mapRef.current.setZoom(zoom);
        if (center) mapRef.current.setCenter(new google.maps.LatLng(center.lat, center.lng));
      }
    }
  }, [markers, center, mapRef, shouldRecenter]);

  if (!isLoaded) return null;

  return (
    <div className={cn("w-full h-full relative", className)}>
      <GoogleMap
        center={center}
        zoom={zoom}
        tilt={0}
        options={{
          disableDefaultUI: true,
          styles: mapStyles,
          gestureHandling: disableGestureHandling ? "none" : "auto",
          zoomControl: disableGestureHandling ? false : true,
          clickableIcons: disableGestureHandling ? false : true,
          draggable: disableGestureHandling ? false : true,
          keyboardShortcuts: disableGestureHandling ? false : true,
          scrollwheel: disableGestureHandling ? false : true,
        }}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      >
        {markers.map((marker, index) => {
          return (
            <OverlayViewF
              key={`marker-${index}`}
              position={marker.coordinates}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={(width, height) => ({
                x: -(width / 2),
                y: -height,
              })}
            >
              {marker.type === "destination" ? (
                <div
                  className={cn(
                    "relative p-2.5 px-3 bg-simple-900 text-white text-[14px] text-center font-bold rounded-[3px] shadow-black/50 shadow-sm"
                  )}
                >
                  {marker.label}
                  <div className={cn("w-2 h-2 bg-simple-900 absolute -bottom-1 left-[50%] -translate-x-[50%] rotate-45 shadow-sm")} />
                </div>
              ) : (
                <div
                  className={cn(
                    "relative p-2 px-2.5 bg-rapide-600 border-2 border-simple-500 text-white text-sm text-center font-bold rounded-[3px] shadow-black/50 shadow-sm transition-all ",
                    hoveredMarkerIndex === index ? "z-10 bg-rapide-600 -translate-y-1 shadow-md" : ""
                  )}
                >
                  {marker.label}
                  <div className="marker-notch" />
                </div>
              )}
            </OverlayViewF>
          );
        })}
      </GoogleMap>

      {streetViewLink || googleMapsLink ? (
        <div className="absolute top-2 right-2 z-50 flex space-x-2">
          {streetViewLink ? (
            <Button type="button" variant="default" color="secondary" asChild>
              <a href={streetViewLink} target="_blank" rel="noreferrer noopener">
                {t("common:ui.openInStreetView")}
                <ExternalLink className="ml-2 h-3 w-3" />
              </a>
            </Button>
          ) : null}

          {googleMapsLink ? (
            <Button type="button" variant="default" color="secondary" asChild>
              <a href={googleMapsLink} target="_blank" rel="noreferrer noopener">
                {t("common:ui.openInGoogleMaps")}
                <ExternalLink className="ml-2 h-3 w-3" />
              </a>
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
