import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { VenuePark } from "@/lib/services/parks.services";
import cn from "@/lib/utils/cn.utils";
import { TimeFormattingUtils } from "@/lib/utils/formatting.utils";
import { CPParkGroupV2, CPParkGroupVenueV2 } from "@clicknpark/sdk";
import { MessageCircleWarning } from "lucide-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";

interface Props {
  label: string;
  park: VenuePark;
  parkGroup?: CPParkGroupV2 | CPParkGroupVenueV2;
  isClosest?: boolean;
  isCheapest?: boolean;
  displayAvailability?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export default function Park({
  label,
  park,
  parkGroup,
  isCheapest,
  isClosest,
  displayAvailability = true,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  const { t } = useTranslation(["search"]);

  const distance = park.directions ? TimeFormattingUtils.secondsToTime(park.directions.walking.duration.value) : undefined;
  const appDownloadRequired = park?.accessType !== "free" && park?.accessType !== "digitalTicket";

  return (
    <li
      className="text-simple-900 mt-3 p-5 border first:border-t border-b border-rapide-300 rounded-[12px] hover:border-simple-900 hover:bg-rapide-100 bg-rapide-50 transition-colors cursor-pointer "
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="flex-1 overflow-hidden">
        <div className="flex items-center justify-between">
          <div className="flex-shrink w-5/6">
            <strong className="block text-base truncate">{park.address.line1}</strong>

            {displayAvailability ? (
              <Fragment>
                {park?.isAvailable ? (
                  <div className="flex items-center text-rapide-600 font-semibold text-xs gap-0.5 mt-0.5">
                    <MaterialSymbol icon="directions_walk" size={16} className="-ml-1 flex-shrink-0" />
                    <span>
                      {distance
                        ? parkGroup
                          ? t("search:walkingDistanceFrom", {
                              distance,
                              location: parkGroup.name,
                            })
                          : t("search:walkingDistanceFromDestination", {
                              distance,
                            })
                        : null}
                    </span>
                  </div>
                ) : (
                  <p className="text-gray-600 text-sm">{t("search:notAvailable")}</p>
                )}
              </Fragment>
            ) : null}
          </div>

          <div className="md:flex hidden w-[33px] h-[33px] basis-[33px] bg-rapide-600 rounded-full text-center text-white items-center justify-center font-bold text-sm">
            {label}
          </div>
        </div>
      </div>

      {park?.isAvailable ? (
        <div className="mt-5 flex items-end justify-between">
          <div>
            {isCheapest || isClosest ? (
              <div className="flex space-x-2">
                {isCheapest ? <Badge variant="outline">{t("search:bestPrice").toUpperCase()}</Badge> : null}
                {isClosest ? <Badge variant="outline">{t("search:closestToDestination").toUpperCase()}</Badge> : null}
              </div>
            ) : null}

            {appDownloadRequired ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <div className={cn("font-bold text-sm text-left leading-tight text-attention-700 flex", isCheapest || isClosest ? "mt-2.5" : "")}>
                      <MessageCircleWarning className="w-4 h-4 mr-1" />
                      {t("search:appDownloadRequired")}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t("search:appDownloadRequiredDescription")}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : null}
          </div>

          {displayAvailability && park.isAvailable ? (
            <Button size="md" color={park.isAvailable ? "primary" : "secondary"} disabled={!park.isAvailable} onClick={onClick}>
              {park.calculatedAmount.formattedToLocale}
            </Button>
          ) : null}
        </div>
      ) : null}
    </li>
  );
}
