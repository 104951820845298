import Wrapper from "@/components/layout/wrapper";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import cn from "@/lib/utils/cn.utils";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  tabs: {
    name: string;
    href: string;
    current: boolean;
  }[];
}

export default function PageTabs({ tabs }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div>
      <div className="pt-3 pb-1 sm:hidden">
        <Wrapper>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <label htmlFor="tabs" className="mb-2 block text-sm font-semibold">
            Navigation:
          </label>

          <Select
            name="tabs"
            value={tabs.find((tab) => location.pathname + location.search === tab.href)?.name}
            onValueChange={(value) => navigate(tabs.find((tab) => tab.name === value)?.href || "")}
          >
            <SelectTrigger className="bg-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {tabs.map((tab) => (
                <SelectItem key={tab.name} value={tab.name}>
                  {tab.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </Wrapper>
      </div>

      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => {
            return (
              <span
                key={tab.name}
                onClick={() => navigate(tab.href)}
                className={cn(
                  tab.current ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-700",
                  "group inline-flex cursor-pointer items-center border-b-2 px-1 pb-3 pt-4 text-sm font-semibold"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                <span>{tab.name}</span>
              </span>
            );
          })}
        </nav>
      </div>
    </div>
  );
}
