import { NavButtonProps } from "@/components/layout/buttons/nav-button";
import { NavLinkProps } from "@/components/layout/buttons/nav-link";
import HeaderNav from "@/components/layout/header-nav";
import Logo from "@/components/layout/logo";
import { getRoute } from "@/lib/configs/routes.config";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import useOnLogout from "@/lib/hooks/use-on-logout";
import { useGetUserQuery } from "@/lib/services/user.services";
import { keys } from "@/lib/utils/storage.utils";
import { Globe, HelpCircle, LogOut, Tag, UserCircle } from "lucide-react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation("auth");
  const { language, alternateLanguage, switchLanguage } = useLanguageUtils();
  const [cookie] = useCookies([keys.AUTH_TOKEN]);
  const authToken = cookie[keys.AUTH_TOKEN];

  const getUserQuery = useGetUserQuery({ enabled: !!authToken });
  const isLoggedIn = !!getUserQuery?.data;

  // const navToProfile = () => navigate(getRoute("ACCOUNT_PROFILE"));
  const navToReservations = () => navigate(getRoute("ACCOUNT_RESERVATIONS"));
  const logout = useOnLogout();

  const loggedInMenuItems: (NavLinkProps | NavButtonProps)[] = [
    {
      stripStyles: true,
      label: t("logout"),
      onClick: logout,
      icon: <LogOut />,
    },
    // {
    //   stripStyles: true,
    //   label: t("profile"),
    //   onClick: () => {},
    //   icon: <UserCircle />,
    // },
    {
      stripStyles: true,
      label: t("reservations"),
      onClick: navToReservations,
      icon: <Tag />,
    },
  ];

  const loggedOutMenuItems: (NavLinkProps | NavButtonProps)[] = [
    {
      label: t("login"),

      href: `${import.meta.env.VITE_CLICKNPARK_AUTH_URL}?redirect=${encodeURIComponent(window.location.href)}`,
      icon: <UserCircle />,
    },
  ];

  return (
    <header data-section="site-header" className="bg-white flex h-20 items-stretch px-4 shadow-md relative z-50">
      <Logo {...{ language: language as "fr" | "en", link: "/" }} />
      <HeaderNav
        menus={[
          {
            items: [],
          },
          {
            items: [
              ...(isLoggedIn ? loggedInMenuItems : loggedOutMenuItems),
              {
                label: "Support",
                icon: <HelpCircle />,
                href: language === "fr" ? "https://clicknpark.com/fr-CA/support" : "https://clicknpark.com/support",
              },
              {
                stripStyles: true,
                label: alternateLanguage.toUpperCase(),
                onClick: () => switchLanguage(alternateLanguage),
                locale: alternateLanguage,
                icon: <Globe />,
              },
            ],
          },
        ]}
      />
    </header>
  );
}
