import { CPMarker } from "@/components/ui/map";
import { DEFAULT_LOCATION } from "@/lib/store/configs/defaut-search-params";
import type {} from "@redux-devtools/extension"; // required for devtools typing
import { create } from "zustand";

interface SearchState {
  /* Map center */
  center: { lat: number; lng: number };
  setCenter: (center: { lat: number; lng: number }) => void;

  /* Map markers */
  markers: CPMarker[];
  setMarkers: (markers: CPMarker[]) => void;

  /* Recenter map flag */
  shouldRecenter: boolean;
  recenter: () => void;

  /* Hovered marker index */
  hoveredMarkerIndex?: number;
  setHoveredMarkerIndex: (index?: number) => void;
}

export const useMapStore = create<SearchState>((set) => ({
  center: DEFAULT_LOCATION,
  setCenter: (center) => set({ center }),
  markers: [],
  setMarkers: (markers) => {
    set({
      markers: markers.map((m) => ({
        ...m,
        coordinates: {
          lat: m.coordinates.lat,
          lng: m.coordinates.lng,
        },
      })),
    });
  },
  shouldRecenter: false,
  recenter: () => {
    set({ shouldRecenter: true });
    setTimeout(() => set({ shouldRecenter: false }), 100);
  },
  hoveredMarkerIndex: undefined,
  setHoveredMarkerIndex: (index) => set({ hoveredMarkerIndex: index || undefined }),
}));
