import { CPUser } from "@clicknpark/sdk";
import * as Sentry from "@sentry/react";
import i18next from "i18next";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

export function configureSentry() {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_ENV,
    enabled: import.meta.env.VITE_ENV === "production",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.5,
  });
}

export function setLoggerUserContext(user: Partial<CPUser>) {
  user = user || null;
  const scope = Sentry.getCurrentScope();
  scope.setUser({
    "User ID": user?.objectId || "N/A",
    "User email": user?.email || "N/A",
    "User preferred locale": user?.locale || "N/A",
    "Navigation language": i18next.language,
    "User fullname":
      user?.firstName && user?.lastName
        ? user.firstName + " " + user.lastName
        : "N/A",
  });
}
