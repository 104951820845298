import { axiosClient, clicknpark } from "@/lib/services/config/api";
import { CPException, CPList } from "@clicknpark/sdk";
import CPReservationV3 from "@clicknpark/sdk/dist/types/entities/v3/reservations/CPReservationV3";
import { ReservationListRequestV3 } from "@clicknpark/sdk/dist/types/services/v3/reservations/ReservationServiceV3Types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// List reservations (GET)
// ========================================

export const LIST_RESERVATIONS_QUERY_KEY = "list-reservations";

export const listReservations = async (request: ReservationListRequestV3): Promise<CPList<CPReservationV3>> => {
  return await clicknpark.reservationsV3.list(request);
};

export const useListReservationsQuery = (request: ReservationListRequestV3) => {
  return useQuery<CPList<CPReservationV3>, CPException, CPList<CPReservationV3>>({
    queryKey: [LIST_RESERVATIONS_QUERY_KEY, request],
    queryFn: () => listReservations(request),
  });
};

// Get reservation (GET)
// ========================================

export const GET_RESERVATION_QUERY_KEY = "get-reservation";

interface GetReservationQueryData {
  id?: string;
  locale: string;
}

export const getReservation = async (data: GetReservationQueryData): Promise<CPReservationV3> => {
  if (!data.id) throw new Error("reservationId is required");
  return await clicknpark.reservationsV3.getById(data.id, data.locale);
};

export const useGetReservationQuery = (data: GetReservationQueryData) => {
  return useQuery<CPReservationV3, CPException, CPReservationV3>({
    queryKey: [GET_RESERVATION_QUERY_KEY, data.id],
    queryFn: () => getReservation(data),
  });
};

// Attach vehicle to reservation (POST)
// ========================================

export const ATTACH_VEHICLE_TO_RESERVATION_MUTATION_KEY = "attach-vehicle-to-reservation";

export interface AttachVehicleToReservationMutationData {
  reservationId: string;
  vehicleId: string;
}

export const attachVehicleToReservation = async ({ reservationId, vehicleId }: AttachVehicleToReservationMutationData) => {
  return await clicknpark.reservationsV2.attachVehicle(reservationId, vehicleId);
};

export const useAttachVehicleToReservationMutation = () => {
  return useMutation<AttachVehicleToReservationMutationData, CPException, AttachVehicleToReservationMutationData>({
    mutationKey: [ATTACH_VEHICLE_TO_RESERVATION_MUTATION_KEY],
    mutationFn: (data: AttachVehicleToReservationMutationData) => attachVehicleToReservation(data),
  });
};

// Get cancellation options (GET)
// ========================================

export type CancellationOption = {
  id: string;
  selected: boolean;
  refunds: {
    amount: number;
    type: "credit" | "paymentMethod";
  }[];
};

export const getCancellationOptions = async (reservationId: string): Promise<CancellationOption[]> => {
  return await axiosClient.get(`/v2/reservations/${reservationId}/cancellationOptions`);
};

export const useGetCancellationOptionsQuery = (reservationId: string) => {
  return useQuery<CancellationOption[], CPException, CancellationOption[]>({
    queryKey: ["getCancellationOptions", reservationId],
    queryFn: () => getCancellationOptions(reservationId),
  });
};

// Cancel reservation (POST)
// ========================================

export interface ReservationCancelRequest {
  reservationId: string;
  proposalId: string;
}

export const useCancelReservationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ reservationId, proposalId }: ReservationCancelRequest) => {
      const { data } = await axiosClient.post(`/v2/reservations/${reservationId}/cancel`, {
        proposalId,
      });
      return data;
    },
    onSuccess: (_, { reservationId }) => {
      queryClient.invalidateQueries({ queryKey: [GET_RESERVATION_QUERY_KEY, reservationId] });
    },
  });
};
