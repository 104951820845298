import { Alert, AlertTitle } from "@/components/ui/alert";

interface Props {
  text: string;
  addBottomPadding?: boolean;
}

export default function InstructionWarning({ text, addBottomPadding }: Props) {
  const Content = () => (
    <Alert variant="softError">
      <AlertTitle>{text}</AlertTitle>
    </Alert>
  );

  if (addBottomPadding) {
    return (
      <div className="pb-3">
        <Content />
      </div>
    );
  }

  return <Content />;
}
