import { Photo } from "@clicknpark/sdk";

interface Props {
  parkPhotos: Photo[];
  selectedParkPhotoIds: string[];
  onPhotoPress: (photos: Photo[], index: number) => void;
  addBottomPadding?: boolean;
}

export default function InstructionPhotos({ parkPhotos, selectedParkPhotoIds, onPhotoPress, addBottomPadding }: Props) {
  const sources = parkPhotos.filter((photo) => selectedParkPhotoIds.includes(photo.id));
  let visibleSources = sources.slice(0, sources.length > 3 ? 2 : sources.length);

  const Content = () =>
    visibleSources.map((source, index) => (
      <button
        key={`gallery-photo-${index}`}
        onClick={() => onPhotoPress(sources, index)}
        className="w-24 h-24 shadow-sm hover:shadow-md transition-all"
      >
        <img key={source.id + index} src={source.medium.url} className="object-cover rounded-md w-full h-full" />
      </button>
    ));

  if (addBottomPadding) {
    return (
      <div className="pb-5 flex flex-wrap gap-3">
        <Content />
      </div>
    );
  }

  return (
    <div className="flex flex-wrap gap-3">
      <Content />
    </div>
  );
}
