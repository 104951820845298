import InstructionPhotos from "@/components/containers/reservation/instructions/instruction-photos";
import InstructionText from "@/components/containers/reservation/instructions/instruction-text";
import InstructionWarning from "@/components/containers/reservation/instructions/instruction-warning";
import Tabs from "@/components/ui/classic-tabs";
import { ParkInstruction, Photo } from "@clicknpark/sdk";
import CPReservationV3 from "@clicknpark/sdk/dist/types/entities/v3/reservations/CPReservationV3";
import { Fragment, useState } from "react";
import Lightbox from "yet-another-react-lightbox";

interface Props {
  reservation: CPReservationV3;
}

export default function ReservationInstructions({ reservation }: Props) {
  const instructions = reservation?.park.accessInstructions;
  const parkPhotos = reservation?.park.medias;

  const [initialIndex, setInitialIndex] = useState<number>(0);
  const [galleryPhotos, setGalleryPhotos] = useState<Photo[]>([]);
  const [isGalleryOpened, setIsGalleryOpened] = useState(false);

  function onPhotoPress(photos: Photo[], index: number) {
    setGalleryPhotos(photos);
    setIsGalleryOpened(true);
    setInitialIndex(index);
  }
  if (!instructions) return null;

  const displayTabs = instructions.length > 1;

  const getInstructionContent = (instruction: ParkInstruction) => {
    return instruction.steps.map((step, index) => {
      let addBottomPaddingToText = false;
      let addBottomPaddingToWarning = false;
      let addBottomPaddingToPhotos = false;

      if (step.text && (step.warning || step.photoIds || step.action)) {
        addBottomPaddingToText = true;
      }

      if (step.warning && (step.photoIds || step.action)) {
        addBottomPaddingToWarning = true;
      }

      if (step.photoIds && step.action) {
        addBottomPaddingToPhotos = true;
      }

      // Do not display action if there is no text, warning or photos
      if (step.action && !step.text && !step.warning && !step.photoIds) {
        return null;
      }

      return (
        <Instruction step={index + 1} key={`instruction-${index}`}>
          {step.text ? <InstructionText {...{ text: step.text, addBottomPadding: addBottomPaddingToText }} /> : null}
          {step.warning ? <InstructionWarning {...{ text: step.warning, addBottomPadding: addBottomPaddingToWarning }} /> : null}
          {step.photoIds ? (
            <InstructionPhotos
              {...{
                parkPhotos,
                onPhotoPress,
                selectedParkPhotoIds: step.photoIds,
                addBottomPadding: addBottomPaddingToPhotos,
              }}
            />
          ) : null}

          {/* {step.action ? <InstructionActionButton {...{ action: step.action }} /> : null} */}
        </Instruction>
      );
    });
  };

  return (
    <Fragment>
      {displayTabs ? (
        <Tabs
          tabs={instructions.map((instruction, index) => ({
            title: instruction.title || `Step ${index + 1}`,
            content: getInstructionContent(instruction),
          }))}
        />
      ) : (
        getInstructionContent(instructions[0])
      )}

      <Lightbox
        open={isGalleryOpened}
        index={initialIndex}
        slides={galleryPhotos.map((photo) => ({ src: photo?.fullSize?.url || "" }))}
        close={() => setIsGalleryOpened(false)}
      />
    </Fragment>
  );
}

function Instruction({ step, children }: { step: number; children: React.ReactNode }) {
  return (
    <div className="flex gap-4 mb-4 last:mb-0">
      <div className="flex-shrink-0 w-8 h-8 rounded-full bg-rapide-600 text-white font-bold text-lg flex items-center justify-center">{step}</div>
      <div>{children}</div>
    </div>
  );
}
