import i18next from "@/lib/i18n";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";

export class DateUtils {
  public static formatDate(date: dayjs.Dayjs, options?: { locale?: string; format?: string }) {
    dayjs.locale(options?.locale || "en");
    const minutes = date.minute();
    const roundedMinutes = Math.round(minutes / 15) * 15;
    const roundedDate = date.minute(roundedMinutes).second(0);

    if (options?.format) {
      return i18next.language === "en" ? roundedDate.format(options.format) : roundedDate.format(options.format);
    }

    if (dayjs().isSame(roundedDate, "day")) {
      return i18next.t("dates:todayAtTime", {
        time: i18next.language === "en" ? roundedDate.format("hh:mm A") : roundedDate.format("HH:mm"),
      });
    } else if (dayjs().add(1, "day").isSame(roundedDate, "day")) {
      return i18next.t("dates:tomorrowAtTime", {
        time: i18next.language === "en" ? roundedDate.format("hh:mm A") : roundedDate.format("HH:mm"),
      });
    } else {
      return i18next.language === "en" ? roundedDate.format("ll hh:mm A") : roundedDate.format("ll HH:mm");
    }
  }

  public static formatDateRelative(date: dayjs.Dayjs, options?: { locale?: string; format?: string; relativeTo?: dayjs.Dayjs }) {
    dayjs.locale(options?.locale || "en");
    const minutes = date.minute();
    const roundedMinutes = Math.round(minutes / 15) * 15;
    const roundedDate = date.minute(roundedMinutes).second(0);
    const relativeTo = options?.relativeTo || dayjs();

    if (options?.format) {
      return i18next.language === "en" ? roundedDate.format(options.format) : roundedDate.format(options.format);
    }

    if (relativeTo.isSame(roundedDate, "day")) {
      return roundedDate.format(i18next.language === "en" ? "hh:mm A" : "HH:mm");
    } else if (relativeTo.add(1, "day").isSame(roundedDate, "day")) {
      return i18next.t("dates:tomorrowAtTime", {
        time: i18next.language === "en" ? roundedDate.format("hh:mm A") : "HH:mm",
      });
    } else {
      return i18next.language === "en" ? roundedDate.format("ll hh:mm A") : roundedDate.format("ll HH:mm");
    }
  }

  public static getClosestQuarter(date: dayjs.Dayjs) {
    const minutes = date.minute();
    const closestQuarter = Math.floor(minutes / 15) * 15;
    return date.minute(closestQuarter).second(0);
  }

  public static getClosestHalf(date: dayjs.Dayjs) {
    const minutes = date.minute();
    const closestHalf = Math.floor(minutes / 30) * 30;
    return date.minute(closestHalf).second(0);
  }
}
