import { DateUtils } from "@/lib/utils/date.utils";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

type UseDatetimepickerProps = {
  /**
   * The initial start date to use in the datetimepicker.
   * Defaults to the closest quarter (15 minutes) to now.
   */
  initialStart?: Dayjs;
  /**
   * The initial end date to use in the datetimepicker.
   * Defaults to now.
   */
  initialEnd?: Dayjs;
};

/**
 * A React Hook for managing start and end dates for a datetimepicker.
 * This hook enforces the following business rules:
 * - Start date can be 15 minutes in the past from now
 * - Start date cannot be after endDate date
 * - End date cannot be before startDate date
 * - End must be at least 1 hour after startDate
 * @param props The props for the hook.
 * @returns An object with the current start date, end date, minimum start date, and minimum end date.
 * Also provides functions to update the start and end dates.
 */
export default function useDatetimepicker(props?: UseDatetimepickerProps) {
  // Set defaults
  const { initialStart = DateUtils.getClosestQuarter(dayjs()), initialEnd = dayjs() } = props || {};

  // Set initial state
  const [startDate, setStartDate] = useState<Dayjs>(initialStart);
  const [endDate, setEndDate] = useState<Dayjs>(initialEnd);

  // Set minimum start and end dates
  const [minStartDate, setMinStartDate] = useState<Dayjs>(DateUtils.getClosestQuarter(dayjs()));
  const [minEndDate, setMinEndDate] = useState<Dayjs>(initialStart.add(1, "hour"));

  /**
   * Updates the start date.
   * Enforces the following business rules:
   * - Start date can be 15 minutes in the past from now
   * - Start date cannot be after endDate date
   * - End date cannot be before startDate date
   * - End must be at least 1 hour after startDate
   * @param incomingStartDate The new start date.
   */
  const handleStartDateChange = (incomingStartDate: Dayjs) => {
    let newStartDate = incomingStartDate;

    // Keep the start min date in sync
    setMinStartDate(DateUtils.getClosestQuarter(dayjs()));

    // Ensure start is at least 15 minutes in the past from now
    if (newStartDate.isBefore(DateUtils.getClosestQuarter(dayjs()))) {
      newStartDate = DateUtils.getClosestQuarter(dayjs());
    }

    // Ensure start is before end date
    if (newStartDate.isAfter(endDate)) {
      setEndDate(newStartDate.add(1, "hour"));
    }

    // Ensure end is at least 1 hour after start date
    if (newStartDate.diff(endDate, "minutes") >= -60 && incomingStartDate.diff(endDate, "minutes") <= 0) {
      setEndDate(newStartDate.add(1, "hour"));
    }

    setStartDate(newStartDate);

    // Update min end date to be 1 hour after new start date
    setMinEndDate(newStartDate.add(1, "hour"));
  };

  /**
   * Updates the end date.
   * Enforces the following business rule:
   * - End date cannot be before start date
   * @param incomingEndDate The new end date.
   */
  const handleEndDateChange = (incomingEndDate: Dayjs) => {
    // Keep the start min date in sync
    setMinStartDate(DateUtils.getClosestQuarter(dayjs()));

    let newEnd = incomingEndDate;

    // Ensure end is not before start date
    if (newEnd.isSameOrBefore(startDate) && newEnd.diff(startDate, "minutes") <= 60) {
      newEnd = startDate.add(1, "hour");
    }

    setEndDate(newEnd);
  };

  return {
    startDate,
    endDate,
    minStartDate,
    minEndDate,
    handleStartDateChange,
    handleEndDateChange,
  };
}
