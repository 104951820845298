import ReservationsList from "@/components/containers/account/reservations-list";
import PageTabs from "@/components/layout/shell/account/tabs";
import Wrapper from "@/components/layout/wrapper";
import Heading from "@/components/ui/heading";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { useListReservationsQuery } from "@/lib/services/reservations.services";
import { CPReservationTypesV3 } from "@clicknpark/sdk";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function ReservationsOngoing() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(["account", "reservations"]);
  const { language } = useLanguageUtils();

  const timekey = (searchParams.get("timekey") || "ongoing") as CPReservationTypesV3.ReservationQueryTimeKey;

  const listReservationsQuery = useListReservationsQuery({
    locale: language as "fr" | "en",
    status: "Valid",
    timekey,
  });

  const reservations = listReservationsQuery.data;

  return (
    <Wrapper>
      <div className="p-5">
        <div className="relative">
          <Heading level="h1">{t("account:reservations")}</Heading>

          <PageTabs
            tabs={[
              {
                name: t("reservations:ongoing"),
                href: "/account/reservations",
                current: timekey === "ongoing",
              },
              {
                name: t("reservations:upcoming"),
                href: "/account/reservations?timekey=upcoming",
                current: timekey === "upcoming",
              },
              {
                name: t("reservations:past"),
                href: "/account/reservations?timekey=past",
                current: timekey === "past",
              },
            ]}
          />

          <ReservationsList reservations={reservations?.items || []} loading={listReservationsQuery.isLoading || listReservationsQuery.isFetching} />
        </div>
      </div>
    </Wrapper>
  );
}
