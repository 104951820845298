import SearchShell from "@/components/layout/shell/search/shell";
import Map from "@/components/ui/map";
import { useMapStore } from "@/lib/store/map.store";
import { Outlet } from "react-router-dom";

export default function Search() {
  const { markers, center, shouldRecenter, hoveredMarkerIndex } = useMapStore();

  /*

  We changed the URL structure on 30/05/2024, it was based on query params, now it's based on path params.
  We need to redirect the user to the new URL structure if they are using the old one.

  */

  return (
    <SearchShell>
      <aside className="md:w-1/2 md:max-w-xl w-full bg-white flex flex-col overflow-auto">
        <Outlet />
      </aside>

      <div className="flex-1 md:min-h-[calc(100vh-68px)] h-auto md:block hidden">
        <Map
          {...{
            markers,
            center,
            shouldRecenter,
            hoveredMarkerIndex,
          }}
        />
      </div>
    </SearchShell>
  );
}
