import { clicknpark } from "@/lib/services/config/api";
import { ExtendedParkGroupListRequest } from "@/types/parks.types";
import { CPException, CPList, CPParkGroupV2, CPParkGroupVenueV2 } from "@clicknpark/sdk";
import { QueryKey, useQuery } from "@tanstack/react-query";

// List Park Groups (GET)
// ========================================

export const LIST_PARKS_GROUPS_QUERY_KEY = "listParksGroups";

export const listParkGroups = async (request: ExtendedParkGroupListRequest): Promise<CPList<CPParkGroupV2 | CPParkGroupVenueV2>> => {
  return await clicknpark.parkGroupsV2.list(request);
};

export const useListParksGroupsQuery = (request: ExtendedParkGroupListRequest) => {
  return useQuery<CPList<CPParkGroupV2 | CPParkGroupVenueV2>, CPException, CPList<CPParkGroupV2 | CPParkGroupVenueV2>>({
    queryKey: [LIST_PARKS_GROUPS_QUERY_KEY, request],
    queryFn: () => listParkGroups(request),
  });
};

// Get park group by ID (GET)
// ========================================

export const GET_PARK_GROUP_QUERY_KEY = "park-group";

type GetParkGroupData = {
  parkGroupId?: string;
  locale?: string;
};

export const getParkGroup = async ({ parkGroupId, locale }: GetParkGroupData) => {
  if (!parkGroupId) throw new Error("parkGroupId is required");
  if (!locale) throw new Error("locale is required");
  return await clicknpark.parkGroupsV2.getById(parkGroupId, { locale });
};

export const useGetParkGroupQuery = (data: GetParkGroupData) => {
  return useQuery<CPParkGroupV2 | CPParkGroupVenueV2, CPException, CPParkGroupV2 | CPParkGroupVenueV2, QueryKey>({
    queryKey: [GET_PARK_GROUP_QUERY_KEY, data.parkGroupId],
    queryFn: () => getParkGroup(data),
    enabled: !!data.parkGroupId,
  });
};