import Header from "@/components/layout/header";
import { Fragment, PropsWithChildren } from "react";

export default function SearchShell({ children }: PropsWithChildren) {
  return (
    <Fragment>
      <Header />
      <main className="flex md:flex-row flex-col md:h-[calc(100vh-68px)] h-auto overflow-hidden">{children}</main>
    </Fragment>
  );
}
