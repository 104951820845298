import { logger } from "@/lib/services/debug/logger.services";
import UtmUtils from "@/lib/utils/utm.utils";
import CPParkV3 from "@clicknpark/sdk/dist/types/entities/v3/parks/CPParkV3";
import posthog from "posthog-js";
import TagManager from "react-gtm-module";

export type RoadblockReason = "unavailable" | "access-type-sesame" | "access-type-valet" | "app-required";

export class TrackingServices {
  /* Generic tracking methods */

  static trackPageView() {
    posthog.capture("$pageview");
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
  static clearGtmEcommerceDataLayer() {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  }

  static trackGtmEvent(event: string, data: Record<string, any>) {
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...data,
        ...UtmUtils.getSavedUtmParams(),
      },
    });
  }

  /* Specific tracking methods */

  // User behavior (interactions with specific UI elements, etc.)
  // ===========================================

  static trackDurationSlotClick(slot: string, price: number, currency: string) {
    posthog.capture("web_checkout_duration_slot_click", {
      timeslot: `${slot}h`,
      amount: {
        currency,
        value: price,
      },
    });

    logger.info("[TRACKING] Posthog:", "Duration slot clicked", {
      timeslot: `${slot}h`,
      amount: {
        currency,
        value: price,
      },
    });
  }

  static trackDurationModeSwitch(newMode: string, oldMode: string) {
    posthog.capture("web_checkout_duration_mode_switch", {
      newMode,
      oldMode,
    });

    logger.info("[TRACKING] Posthog:", "Duration mode switched", {
      newMode,
      oldMode,
    });
  }

  // Track conversion events (e.g. moving to checkout, payment success, etc.)
  // ===========================================

  static trackRoadblock(reasons: RoadblockReason[]) {
    posthog.capture("web_checkout_roadblock", {
      reasons,
    });
  }

  static trackParkView(park: CPParkV3) {
    posthog.capture("web_park_view", {
      items: [
        {
          item_id: park.id,
          item_name: park.address.line1,
        },
      ],
    });

    logger.info("[TRACKING] Posthog:", "web_park_view", {
      items: [
        {
          item_id: park.id,
          item_name: park.address.line1,
        },
      ],
    });

    this.clearGtmEcommerceDataLayer();
    this.trackGtmEvent("view_item", {
      ecommerce: {
        items: [
          {
            item_id: park.id,
            item_name: park.address.line1,
          },
        ],
      },
    });

    logger.info("[TRACKING] G4A:", "view_item", {
      ecommerce: {
        items: [
          {
            item_id: park.id,
            item_name: park.address.line1,
          },
        ],
      },
    });
  }

  static trackClickedDownloadApp(price: number, currency: string, park: CPParkV3, isAndroidOrIOS: boolean) {
    posthog.capture("web_clicked_download_app", {
      isAndroidOrIOS,
      currency: currency,
      value: price,
      items: [
        {
          item_id: park.id,
          item_name: park.address.line1,
          item_category: "reservation",
          price: price,
          quantity: 1,
        },
      ],
    });

    logger.info("[TRACKING] Posthog:", "Clicked download app");
  }
}
