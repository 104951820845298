import Header from "@/components/layout/header";
import Loader from "@/components/layout/loader";
import { useGetUserQuery } from "@/lib/services/user.services";
import { keys } from "@/lib/utils/storage.utils";
import { CPException } from "@clicknpark/sdk";
import { Fragment, PropsWithChildren, useEffect } from "react";
import { useCookies } from "react-cookie";

export default function AccountShell({ children }: PropsWithChildren) {
  const [cookie, _, removeCookie] = useCookies([keys.AUTH_TOKEN]);
  const authToken = cookie[keys.AUTH_TOKEN];
  const getUserQuery = useGetUserQuery({ enabled: !!authToken });

  const redirectToAuth = () => {
    window.location.href = `${import.meta.env.VITE_CLICKNPARK_AUTH_URL as string}?redirect=${encodeURIComponent(window.location.href)}`;
  };

  useEffect(() => {
    // wait until the user query is done loading
    if (getUserQuery.isLoading) return;

    if (!authToken) {
      redirectToAuth();
    }

    // authtoken is invalid or expired
    if (getUserQuery.isError) {
      if (getUserQuery.error instanceof CPException) {
        if (getUserQuery.error.code === 4000 || getUserQuery.error.code === 4001) {
          removeCookie(keys.AUTH_TOKEN);
          redirectToAuth();
        }
      }
    }
  }, [getUserQuery, authToken, removeCookie]);

  if (!authToken) {
    return <Loader />;
  }

  if (getUserQuery.isLoading) {
    return <Loader />;
  }

  if (getUserQuery.isError && getUserQuery.error instanceof CPException) {
    if (getUserQuery.error.code === 4000 || getUserQuery.error.code === 4001) {
      return <Loader />;
    } else {
      return <div>{getUserQuery.error.message}</div>;
    }
  }

  return (
    <Fragment>
      <Header />
      <main className="bg-white">
        {/* <Sidebar
            links={[
              { href: getRoute("ACCOUNT_PROFILE"), label: t("account:profile") },
              { href: getRoute("ACCOUNT_RESERVATIONS"), label: t("account:reservations") },
            ]}
          /> */}

        {children}
      </main>
    </Fragment>
  );
}
