import cn from "@/lib/utils/cn.utils";
import * as React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  integratedLabel?: string;
  integratedLabelClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const InputComponent = (
      <input
        type={type}
        className={cn(
          "flex h-9 w-full border border-slate-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
          className,
          props.integratedLabel ? "rounded-r-md" : "rounded-md"
        )}
        ref={ref}
        {...props}
      />
    );

    if (props.integratedLabel) {
      return (
        <div className="flex">
          <label
            htmlFor={props.id}
            className={cn(
              "min-w-fit text-sm h-9 py-1 px-3 flex items-center border-slate-200 border border-r-0 rounded-l-md bg-silver-100",
              props.integratedLabelClassName
            )}
          >
            {props.integratedLabel}
          </label>

          {InputComponent}
        </div>
      );
    }

    return InputComponent;
  }
);

Input.displayName = "Input";

export { Input };
