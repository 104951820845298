import cn from "@/lib/utils/cn.utils";

interface Props {
  children: React.ReactNode;
  title?: string;
  padding?: ("left" | "right" | "top" | "bottom")[];
  className?: string;
}

export default function ParkSection({ children, title, padding = ["left", "right", "top", "bottom"], className }: Props) {
  let paddingClassNames = "";

  if (padding.includes("left")) paddingClassNames += "pl-5 ";
  if (padding.includes("right")) paddingClassNames += "pr-5 ";
  if (padding.includes("top")) paddingClassNames += "pt-5 ";
  if (padding.includes("bottom")) paddingClassNames += "pb-5 ";

  return (
    <div className={cn("bg-white", paddingClassNames, className)}>
      {title ? <h2 className="text-base font-bold text-simple-900 mb-4">{title}</h2> : null}
      {children}
    </div>
  );
}
