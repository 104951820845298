import { Badge, BadgeProps } from "@/components/ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { CPParkFeature } from "@clicknpark/sdk";
import CPParkV3 from "@clicknpark/sdk/dist/types/entities/v3/parks/CPParkV3";
import { useTranslation } from "react-i18next";

interface Props {
  features?: CPParkFeature;
  type?: CPParkV3["type"];
}

export default function ParkFeatures({ features, type }: Props) {
  const { t } = useTranslation(["park"]);

  if (!features || !type) return null;

  /* Features */
  const disabledAccess = features?.spacesReservedForDisabled;
  const hasCCTV = features?.hasCCTV;
  const hasStaff = features?.hasStaff;
  const hasCarWash = features?.hasCarWash;
  const wideness = features?.wideness;

  const badges: {
    size?: BadgeProps["size"];
    variant: BadgeProps["variant"];
    text: string;
    description?: string;
  }[] = [
    {
      size: "sm",
      variant: "outlineReverse",
      text: t(`park:${type}`),
    },
  ];

  if (disabledAccess) {
    badges.push({
      variant: "outlineReverse",
      text: t("park:features.spacesReservedForDisabled"),
      description: t("park:features.spacesReservedForDisabledDescription"),
    });
  }

  if (hasCCTV) {
    badges.push({ size: "sm", variant: "outlineReverse", text: t("park:features.hasCCTV"), description: t("park:features.hasCCTVDescription") });
  }

  if (hasStaff) {
    badges.push({ size: "sm", variant: "outlineReverse", text: t("park:features.hasStaff"), description: t("park:features.hasStaffDescription") });
  }

  if (hasCarWash) {
    badges.push({
      size: "sm",
      variant: "outlineReverse",
      text: t("park:features.hasCarWash"),
      description: t("park:features.hasCarWashDescription"),
    });
  }

  if (wideness) {
    badges.push({ size: "sm", variant: "outlineReverse", text: t("park:features.wideness"), description: t("park:features.widenessDescription") });
  }

  return (
    <div className="flex mt-2 flex-row items-end space-x-2">
      {badges?.map((badge, index) =>
        badge.description ? (
          <TooltipProvider key={index}>
            <Tooltip>
              <TooltipTrigger>
                <Badge key={index} variant={badge.variant} size={badge.size}>
                  {badge.text}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>
                <p>{badge.description}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <Badge key={index} variant={badge.variant} size={badge.size}>
            {badge.text}
          </Badge>
        )
      )}
    </div>
  );
}
