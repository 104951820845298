import cn from "@/lib/utils/cn.utils";

interface Props {
  width?: number;
  height?: number;
  alt?: string;
  language: "fr" | "en";
  link?: string;
  className?: string;
}

export default function Logo({ width = 175, height = 32, alt = "clicknpark logo", language, link, className }: Props) {
  let Image = (
    <img
      {...{
        width,
        height,
        alt,
        src: `/images/layout/logo-${language}-CA.svg`,
        className: !link ? className : "", // do not use className if logo is wrapped in a link
      }}
    />
  );

  if (link)
    return (
      <a href={link} className={cn("flex items-center", className)}>
        {Image}
      </a>
    );

  return Image;
}
