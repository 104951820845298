import cn from "@/lib/utils/cn.utils";
import React, { Fragment, useState } from "react";

interface TabConfig {
  title: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: TabConfig[];
}

export default function Tabs({ tabs }: TabsProps) {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Fragment>
      <ul className="flex gap-2">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={cn(
              "bg-silver-800 py-2 pt-2.5 px-3 rounded-tl-md rounded-tr-md text-base font-semibold border-b-transparent",
              tabIndex === index ? "bg-white cursor-default border border-silver-600 border-b-white -mb-0.5" : "hover:bg-silver-700 cursor-pointer"
            )}
            onClick={() => setTabIndex(index)}
          >
            {tab.title}
          </li>
        ))}
      </ul>

      <div className={cn("p-5 border-silver-600 border rounded-bl-md rounded-br-md rounded-tr-md")}>{tabs[tabIndex].content}</div>
    </Fragment>
  );
}
