import ReservationInstructions from "@/components/containers/reservation/instructions";
import { CancelReservationDialog } from "@/components/dialogs/cancel-reservation-dialog";
import { VehiclePlateDialog } from "@/components/dialogs/vehicle-plate-dialog";
import Wrapper from "@/components/layout/wrapper";
import { Alert, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import Heading from "@/components/ui/heading";
import Spinner from "@/components/ui/spinner";
import useDetectOs from "@/lib/hooks/use-detect-os";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { logger } from "@/lib/services/debug/logger.services";
import { useGetReservationQuery } from "@/lib/services/reservations.services";
import { DateUtils } from "@/lib/utils/date.utils";
import { keys } from "@/lib/utils/storage.utils";
import UtmUtils from "@/lib/utils/utm.utils";
import branch from "branch-sdk";
import dayjs from "dayjs";
import { ArrowRight, Car, Download, HelpCircle, Navigation } from "lucide-react";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";

interface Params extends Record<string, string> {
  reservationId: string;
}

type Modal = "vehiclePlate" | "cancelReservation" | "downloadApp";

export default function Reservation() {
  const { language } = useLanguageUtils();
  const { t } = useTranslation(["reservations", "park"]);
  const { isAndroidOrIOS } = useDetectOs();
  const [cookie] = useCookies([keys.AUTH_TOKEN]);

  const [loading, setLoading] = useState<boolean>(true);
  const [deferredDeepLink, setDeferredDeepLink] = useState<string>("#");
  const [modal, setModal] = useState<Modal | undefined>();
  const open = (modal: Modal) => setModal(modal);
  const close = () => setModal(undefined);

  const params = useParams<Params>();
  const reservationId = params.reservationId;
  const getReservationQuery = useGetReservationQuery({ id: reservationId, locale: language });
  const reservation = getReservationQuery.data;

  let vehicleModelName = reservation?.vehicle?.model ? `${reservation.vehicle.model.make} ${reservation.vehicle.model.model}` : "";
  if (reservation?.vehicle?.noModelString) vehicleModelName = reservation.vehicle.noModelString;

  const supportLink = language === "fr" ? "https://clicknpark.com/fr-CA/support" : "https://clicknpark.com/support";
  const appDownloadRequired = reservation?.park.accessType !== "free" && reservation?.park.accessType !== "digitalTicket";

  useEffect(() => {
    if (!appDownloadRequired) return;

    setLoading(true);

    branch.link(
      {
        channel: "web-app",
        feature: "open-reservation",
        campaign: UtmUtils.getSavedUtmParams().campaign,
        data: {
          action: {
            type: "open-reservation",
            payload: {
              id: reservationId,
              authToken: cookie.cnp_auth_token,
            },
          },
          utmParams: UtmUtils.getSavedUtmParams(),
          posthogDistinctId: posthog.get_distinct_id(),
        },
      },
      (err, res) => {
        if (res) setDeferredDeepLink(res);
        logger.error(err, res);
      }
    );

    setLoading(false);
  }, []);

  const AppDownloadButton = () => (
    <Button
      size="sm"
      className="w-full max-w-[320px] mt-3"
      disabled={loading}
      onClick={() => {
        if (!isAndroidOrIOS) open("downloadApp");
      }}
    >
      <Download className="mr-2 w-5 h-5" />
      {t("reservations:downloadTheApp")}
    </Button>
  );

  return getReservationQuery.isLoading ? (
    <div className="p-24">
      <Spinner />
    </div>
  ) : reservation ? (
    <>
      <header className="text-center bg-simple-900 p-6 py-10 md:p-16">
        <Wrapper>
          {reservation.accessTicket ? (
            <div className="bg-simple-900 border-2 border-white p-8 rounded-xl flex flex-col items-center justify-center mb-8">
              <div className="p-2.5 bg-white rounded-md">
                <QRCode value={reservation.accessTicket.ticketCode} className="w-24 h-24" />
              </div>
              <p className="mt-5 font-bold text-white">{t("reservations:qrCodeInstructions")}</p>
              <p className="text-sm text-white mt-3 font-medium">{t("reservations:qrCodeLuminosity")}</p>
            </div>
          ) : null}

          <p className="text-rapide-600 font-bold mb-1">{t("yourReservation")}</p>

          <Heading level="h1" className="leading-tight text-white font-extrabold font-heading">
            {reservation.park.address.line1}
          </Heading>

          <div className="mt-5 flex items-center justify-center space-x-4 text-white">
            <div>
              <div className="font-bold text-base">{t("reservations:arriveAfter")}</div>
              {DateUtils.formatDate(dayjs(reservation.startDate))}
            </div>
            <ArrowRight className="inline-block mx-2 size-6" />
            <div>
              <div className="font-bold text-base">{t("reservations:leaveBefore")}</div>
              {DateUtils.formatDate(dayjs(reservation.endDate))}
            </div>
          </div>

          <Alert variant="info" className="bg-white text-simple-900 max-w-fit mx-auto pr-2.5 mt-5">
            <AlertTitle>{t("reservations:accessValidFor")}</AlertTitle>
          </Alert>

          <div className="flex justify-center gap-4 mt-10">
            <Button size="sm" className="w-24 h-24 flex flex-col gap-2" asChild>
              <a href={supportLink} target="_blank" rel="noreferrer noopener">
                <HelpCircle className="w-8 h-8" />
                {t("reservations:getSupport")}
              </a>
            </Button>
            <Button size="sm" className="w-24 h-24 flex flex-col gap-2" asChild>
              <a
                href={`https://www.google.com/maps/search/${reservation.park.location.latitude},${reservation.park.location.longitude}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Navigation className="w-8 h-8" />
                {t("reservations:directions")}
              </a>
            </Button>
          </div>
        </Wrapper>
      </header>

      <div className="p-6 text-center border-b">
        <Heading level="h1" className="text-md">
          {t("downloadTheAppTitle")}
        </Heading>
        <p className="leading-relaxed text-sm border-rapide-600 mt-2">{t("downloadTheAppDescription")}</p>
        {isAndroidOrIOS ? (
          <a className="block cursor-pointer" href={deferredDeepLink}>
            <AppDownloadButton />
          </a>
        ) : (
          <AppDownloadButton />
        )}
      </div>

      {reservation.park.accessType === "digitalTicket" || reservation?.park.accessType === "free" ? (
        <>
          <div className="p-6 md:p-16 border-b">
            <Heading level="h2" className="flex items-center gap-4 mb-6">
              <div className="flex items-center bg-rapide-100 justify-center p-1 rounded-md">
                <Navigation className="w-6 h-6 text-rapide-600" />
              </div>
              {t("reservations:howToGetThere")}
            </Heading>

            <ReservationInstructions {...{ reservation }} />
          </div>

          <div className="p-6 md:p-16 border-b">
            <Heading level="h2" className="justify-between flex items-center mb-6">
              <div className="flex items-center gap-4">
                <div className="flex items-center bg-rapide-100 justify-center p-1 rounded-md">
                  <Car className="w-6 h-6 text-rapide-600" />
                </div>
                {t("reservations:vehicle")}
              </div>
              <Button size="sm" color="secondary" className="mt-4" onClick={() => open("vehiclePlate")}>
                {t("reservations:changeVehicle")}
              </Button>
            </Heading>

            {vehicleModelName !== "_UNKNOWN_" ? (
              <p className="text-base">
                <strong>{t("reservations:model")}:</strong> {vehicleModelName}
              </p>
            ) : null}

            <p className="text-base">
              <strong>{t("reservations:plate")}:</strong> {reservation.vehicle.plate}
            </p>
          </div>

          {reservationId ? (
            <>
              <VehiclePlateDialog
                {...{
                  reservationId: reservationId,
                  isOpen: modal === "vehiclePlate",
                  onClose: close,
                  onSuccess: close,
                  onError: close,
                }}
              />

              <CancelReservationDialog
                {...{
                  reservationId: reservationId,
                  isOpen: modal === "cancelReservation",
                  onClose: close,
                  onSuccess: close,
                  onError: close,
                }}
              />
            </>
          ) : null}
        </>
      ) : (
        <div className="p-5 text-center border-4 border-rapide-600 rounded-md mt-10 max-w-lg mx-auto mb-6">
          <p>{t("reservations:accessTypeNotSupported")}</p>

          {isAndroidOrIOS ? (
            <a className="block cursor-pointer" href={deferredDeepLink}>
              <AppDownloadButton />
            </a>
          ) : (
            <AppDownloadButton />
          )}
        </div>
      )}

      <Dialog open={modal === "downloadApp"} onOpenChange={close}>
        <DialogContent className="pb-0 border-0">
          <div className="flex justify-between items-end">
            <div className="w-2/3 flex-1 space-y-3 mb-5 text-base">
              <Heading className="pr-5" level="h3">
                {t("park:scanQRToDownload")}
              </Heading>

              {isAndroidOrIOS ? <p>{t("park:scanQRToDownloadWarningAuthMobile")}</p> : <p>{t("park:scanQRToDownloadWarningAuthQR")}</p>}

              {loading ? (
                <div className="w-[120px] h-[120px] border border-silver-300 flex items-center justify-center rounded-md">
                  <Spinner />
                </div>
              ) : (
                <QRCode size={120} value={deferredDeepLink} />
              )}
            </div>

            <img src="/images/app.png" className="h-[280px] ml-5" alt="" />
          </div>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
}
